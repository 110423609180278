import React from 'react';
import Grid from '@mui/material/Grid';
import Typography from '@mui/material/Typography';
import Button from '@mui/material/Button';
import GenericTableInvoices from '../../components/genericTableInvoice';
import { GenericTableObs, GenericTablePrice } from '../../components/genericTablePrice';
import logo from '../../assets/images/tundra.svg';
import InfoOutlinedIcon from '@mui/icons-material/InfoOutlined';
import Popover from '@mui/material/Popover';
import { NavbarGrid, Card } from './styles';
import { useQuery } from '@apollo/client';
import { useParams } from 'react-router-dom';
import { GET_PARTNER_INVOICE_BY_ID } from '../../gql/public';
import moment from 'moment';
import { formatBrl } from '../../utils/numbers';
import { formatToTimeZone } from 'date-fns-timezone';
import { spacing } from '../../theme/themeCreator';

const timezone = { timeZone: 'UTC' };

const InvoiceReader = () => {
  const { partnerId, invoiceId } = useParams();
  const {
    data: invoiceData,
    error,
    loading,
  } = useQuery(GET_PARTNER_INVOICE_BY_ID, {
    variables: { input: { partnerId, invoiceId } },
  });

  const [anchorEl, setAnchorEl] = React.useState<HTMLButtonElement | null>(null);
  const handleClick = (event: React.MouseEvent<HTMLButtonElement>) =>
    setAnchorEl(event.currentTarget);

  const handleClose = () => setAnchorEl(null);

  const open = Boolean(anchorEl);
  const id = open ? 'simple-popover' : undefined;

  if (loading || error) return null;

  const { getPartnerInvoice } = invoiceData;
  const {
    data,
    saleId,
    startDate,
    endDate,
    totalValue,
    dueDates,
    formattedTier,
    paymentType,
    subTotal,
    discounts,
  } = getPartnerInvoice;

  return (
    <Grid item sx={{ background: 'primary' }} xs={12} sm={6} md={3}>
      <NavbarGrid
        xs={12}
        sm={8}
        md={4}
        sx={{
          display: { xs: 'block', md: 'block', sm: 'none' },
        }}
      >
        <Grid container alignItems="center" justifyContent="space-between">
          <Grid item>
            <img width={200} src={logo} alt="" />
          </Grid>
          <Grid item>
            <Typography sx={{ color: 'white' }} variant="h4">
              Relatório de consumo
            </Typography>
          </Grid>
          <Grid sx={{ display: 'flex', alignItems: 'flex-end', flexDirection: 'column' }}>
            <Typography variant="h6" sx={{ color: 'white' }}>
              Studica Brasil Software Eireli
              <Button onClick={handleClick} sx={{ marginBottom: 0.5 }}>
                <InfoOutlinedIcon sx={{ color: 'white' }} />
              </Button>
            </Typography>
            <Popover
              sx={{ padding: 1, marginTop: 3, marginRight: 5 }}
              id={id}
              open={open}
              anchorEl={anchorEl}
              onClose={handleClose}
              anchorOrigin={{
                vertical: 'bottom',
                horizontal: 'right',
              }}
            >
              <Grid
                item
                sx={{ padding: 2, display: 'flex', gap: spacing.xl, flexDirection: 'column' }}
              >
                <Typography variant="body1">
                  <b> CNPJ:</b> 11.943.666/0001-08
                </Typography>
                <Typography variant="body1">
                  <b> Endereço:</b> Rua Bacaetava, 401 - Conj 41, Vila Gertrudes, São
                  Paulo - SP
                </Typography>

                <Typography variant="body1">
                  <b>CEP:</b> 04705-010
                </Typography>
              </Grid>
            </Popover>
          </Grid>
        </Grid>
      </NavbarGrid>
      <Grid sx={{ padding: { md: 5, sm: 2 } }}>
        <Typography variant="h4" sx={{ pb: 3 }}>
          Pedido de venda #{saleId}
        </Typography>
        <Grid container spacing={2}>
          <Grid item xs={12} sm={6} md={3}>
            <Card>
              <Typography
                variant="h5"
                fontWeight="bold"
                sx={{ fontSize: { sm: 20, md: 25 } }}
              >
                Cliente:
              </Typography>
              <Typography variant="body1">
                Nome: {data.client.companyName} <br />
                Nome fantasia: {data.client.tradingName} <br />
                CNPJ: {data.client.cnpj}
              </Typography>
            </Card>
          </Grid>
          <Grid item xs={12} sm={6} md={3}>
            <Card>
              <Typography
                variant="h5"
                fontWeight="bold"
                sx={{ fontSize: { sm: 20, md: 25 } }}
              >
                Endereço:
              </Typography>
              <Typography variant="body1">
                Rua: {data.companyAddress.street} <br />
                Cidade: {data.companyAddress.city} <br />
                CEP: {data.companyAddress.zipcode} <br />
              </Typography>
            </Card>
          </Grid>
          <Grid item xs={12} sm={6} md={3}>
            <Card>
              <Typography
                variant="h5"
                fontWeight="bold"
                sx={{ fontSize: { sm: 20, md: 25 } }}
              >
                Contato:
              </Typography>
              <Typography variant="body1">
                Fone: {data.contact.telephone} <br />
                Celular: {data.contact.contactNumber} <br />
                Email: {data.contact.contactEmail} <br />
              </Typography>
            </Card>
          </Grid>
          <Grid item xs={12} sm={6} md={3}>
            <Card>
              <Typography
                variant="h5"
                fontWeight="bold"
                sx={{ fontSize: { sm: 20, md: 25 } }}
              >
                Período:
              </Typography>
              <Typography variant="body1">
                Data:
                {formatToTimeZone(
                  new Date(Number(startDate)),
                  'DD/MM/YYYY',
                  timezone
                )} à {formatToTimeZone(new Date(Number(endDate)), 'DD/MM/YYYY', timezone)}{' '}
                <br />
                Vencimentos:{' '}
                {dueDates.map((date: number) => moment(date).format('DD/MM/YYYY'))}
                <br />
                VL. Total: {formatBrl(totalValue)}
              </Typography>
            </Card>
          </Grid>
        </Grid>

        <Typography
          sx={{
            paddingTop: 5,
            fontSize: { md: 30, sm: 20 },
          }}
        >
          Relatório de consumo
        </Typography>
        <GenericTableInvoices regions={data.regions} />

        <Grid sx={{ display: 'block' }}>
          <Grid sx={{ marginBottom: 2, marginTop: 2 }}>
            <GenericTablePrice
              totalValue={totalValue}
              subTotal={subTotal}
              discounts={discounts}
            />
          </Grid>

          <GenericTableObs
            invoiceData={data}
            tier={formattedTier}
            paymentType={paymentType}
          />
        </Grid>
      </Grid>
    </Grid>
  );
};
export default InvoiceReader;
