import React from 'react';
import Paper from '@mui/material/Paper';
import { useMutation, useQuery } from '@apollo/client';
import GenericTable from '../../components/genericTable';
import { useNavigate } from 'react-router-dom';
import { DELETE_TAX, GET_ALL_TAXES } from '../../gql/taxes';

const columnsOptions = {
  id: {
    hide: true,
  },
  description: {
    headerName: 'Descrição do Imposto',
  },
  type: {
    headerName: 'Tipo',
  },
  value: {
    headerName: 'Valor sugerido',
  },
};

const TaxesRoute = () => {
  const navigate = useNavigate();
  const { data, loading } = useQuery(GET_ALL_TAXES);
  const [deleteTax] = useMutation(DELETE_TAX, {
    refetchQueries: [{ query: GET_ALL_TAXES }],
  });

  const handleEditTax = ({ id = '' }: { id?: string }) =>
    navigate(`/dashboard/taxes/${id}`);
  const handleDeleteTax = ({ id = '' }) => deleteTax({ variables: { id } });

  return (
    <Paper sx={{ width: '100%', mb: 2 }}>
      <GenericTable
        data={data?.getAllTaxes}
        loading={loading}
        columnOptions={columnsOptions}
        onEdit={handleEditTax}
        onDelete={handleDeleteTax}
      />
    </Paper>
  );
};

export default TaxesRoute;
