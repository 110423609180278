import React, { useState, useMemo } from 'react';
import Paper from '@mui/material/Paper';
import { useMutation, useQuery } from '@apollo/client';
import GenericTable from '../../components/genericTable';
import Header from '../../components/header';
import { useNavigate } from 'react-router-dom';
import { DELETE_INVOICE, GET_ALL_INVOICES, RUN_INVOICING } from '../../gql/invoices';
import { invoiceStatus } from '../../utils/forms';
import CreateInvoiceDraft from './createDraftInvoice';
import { formatToTimeZone } from 'date-fns-timezone';
import SpeedDialComponent from '../../components/speedDial';
import { Description, DescriptionOutlined, Task } from '@mui/icons-material';
import ContractsRoute from '../contracts';
import ProductsRoute from '../products';
import TaxesRoute from '../taxes';
import WalletsRoute from '../wallet/index';
import useLocationName from '../../hooks/useLocationName';
import ButtonGroup from '../../components/buttonGroup';
import usePermissions from '../../hooks/usePermissions';
import { ButtonTopTable } from '../users/style';
import invoices from '../../assets/images/invoices.svg';
import contracts from '../../assets/images/contracts.svg';
import conections from '../../assets/images/conections.svg';
import products from '../../assets/images/products.svg';
import taxes from '../../assets/images/taxes.svg';
import ButtonActionHeader from '../../components/HeaderButton/style';
import Grid from '@mui/material/Grid';

const timezone = { timeZone: 'UTC' };
const toBRL = new Intl.NumberFormat('pt-BR', { style: 'currency', currency: 'BRL' });

const InvoicesRoute = () => {
  const { isAdmin, isOwner } = usePermissions();

  const columnsOptions = useMemo(
    () => ({
      id: {
        hide: true,
        hideable: false,
        filterable: false,
      },
      immutableData: {
        hide: true,
        hideable: false,
        filterable: false,
        headerName: 'Dados',
      },
      startDate: {
        hide: true,
        hideable: false,
        filterable: false,
      },
      tenantId: {
        hide: true,
        hideable: false,
        filterable: false,
      },
      contract: {
        hide: true,
        hideable: false,
        filterable: false,
      },
      saleId: {
        position: 0,
        headerName: 'ID da venda',
        maxWidth: 100,
        hideable: false,
      },
      status: {
        headerName: 'Status',
        valueGetter: ({ value }: Record<any, any>) => invoiceStatus[value],
      },
      endDate: {
        headerName: 'Periodo Faturamento',
        valueGetter: ({ row }: any) =>
          `${formatToTimeZone(
            new Date(Number(row.startDate)),
            'DD/MM/YYYY',
            timezone
          )} à ${formatToTimeZone(
            new Date(Number(row.endDate)),
            'DD/MM/YYYY',
            timezone
          )}`,
      },
      dueDates: {
        headerName: 'Vencimentos',
        valueGetter: ({ value }: any) =>
          value
            .map(
              (e: string) => `${formatToTimeZone(new Date(e), 'DD/MM/YYYY', timezone)}`
            )
            .join(' | '),
      },
      partnerId: {
        headerName: 'Cliente',
        valueGetter: ({ row }: any) =>
          row.immutableData?.client?.tradingName ?? row.partnerId,
      },
      totalValue: {
        headerName: 'Valor do Pedido',
        valueGetter: ({ value, row }: any) => {
          if (isAdmin || isOwner(row.contract?.sellerId)) {
            return toBRL.format(value);
          }
          return 'N/A';
        },
        sortComparator: (a: string, b: string) =>
          Number(a.slice(3).replace('.', '').replace(',', '.')) -
          Number(b.slice(3).replace('.', '').replace(',', '.')),
        hideable: false,
      },
      createdAt: {
        headerName: 'Emissão',
        valueGetter: ({ value }: any) =>
          formatToTimeZone(new Date(Number(value)), 'DD/MM/YYYY', timezone),
      },
    }),
    [isAdmin]
  );

  const navigate = useNavigate();
  const { data, loading } = useQuery(GET_ALL_INVOICES);
  const [runInvoicing] = useMutation(RUN_INVOICING);
  const [deleteInvoice] = useMutation(DELETE_INVOICE, {
    refetchQueries: [{ query: GET_ALL_INVOICES }],
  });

  const [viewModal, setViewModal] = useState(false);
  const dialOptions = [
    {
      key: 'SaleOrder',
      icon: <DescriptionOutlined />,
      tooltipTitle: 'Emitir Pedido de Vendas',
      onClick: () => setViewModal(true),
    },
    {
      key: 'Preview',
      icon: <Description />,
      tooltipTitle: 'Criar Preview',
      onClick: () => setViewModal(true),
    },
    {
      key: 'Emission',
      icon: <Task />,
      tooltipTitle: 'Emitir Notas',
      onClick: async () => {
        await runInvoicing();
      },
    },
  ];

  const handleEditInvoice = ({ id = '' }: { id?: string }) => navigate(id);
  const handleDeleteInvoice = ({ id = '' }) => deleteInvoice({ variables: { id } });

  const tabNames: Record<string, string> = {
    invoices: 'Pedidos de Venda',
    contracts: 'Contratos',
    products: 'Produtos',
    taxes: 'Impostos',
    wallets: 'Conexões',
  };

  const singularTabNames: Record<string, string> = {
    invoices: 'Pedido de Venda',
    contracts: 'Contrato',
    products: 'Produto',
    taxes: 'Imposto',
    wallets: 'Conexão',
  };

  const currentTab = useLocationName();

  const handleChangeTabs = (newTab: React.SetStateAction<string>) => () =>
    navigate(`/dashboard/${newTab}`);

  const handleCreate = () => {
    navigate('create');
  };

  const article = currentTab === 'wallets' ? 'nova' : 'novo';

  return (
    <>
      <CreateInvoiceDraft viewModal={viewModal} onClose={setViewModal} />
      <Grid container alignItems="center" justifyContent="space-between">
        <Header title={tabNames[currentTab]} />
        {isAdmin ? (
          <ButtonActionHeader onClick={handleCreate}>
            {`Criar ${article} ${singularTabNames[currentTab]}`}
          </ButtonActionHeader>
        ) : null}
      </Grid>
      <ButtonGroup variant="contained" size="large">
        <ButtonTopTable
          onClick={handleChangeTabs('invoices')}
          className={currentTab === 'invoices' ? 'button-group-selected' : ''}
        >
          <img src={invoices} alt="" /> Pedidos de venda
        </ButtonTopTable>

        <ButtonTopTable
          onClick={handleChangeTabs('contracts')}
          className={currentTab === 'contracts' ? 'button-group-selected' : ''}
        >
          <img src={contracts} alt="" /> Contratos
        </ButtonTopTable>

        <ButtonTopTable
          onClick={handleChangeTabs('wallets')}
          className={currentTab === 'wallets' ? 'button-group-selected' : ''}
        >
          <img src={conections} alt="" /> Conexões
        </ButtonTopTable>

        <ButtonTopTable
          onClick={handleChangeTabs('products')}
          className={currentTab === 'products' ? 'button-group-selected' : ''}
        >
          <img src={products} alt="" /> Produtos
        </ButtonTopTable>

        {isAdmin && (
          <ButtonTopTable
            onClick={handleChangeTabs('taxes')}
            className={currentTab === 'taxes' ? 'button-group-selected' : ''}
          >
            <img src={taxes} alt="" /> Impostos
          </ButtonTopTable>
        )}
      </ButtonGroup>
      <Paper sx={{ width: '100%', mb: 2 }}>
        {currentTab === 'invoices' && (
          <>
            <GenericTable
              data={data?.getAllInvoices}
              loading={loading}
              columnOptions={columnsOptions}
              onEdit={handleEditInvoice}
              onDelete={handleDeleteInvoice}
              hideDeleteButton={!isAdmin}
            />
            {isAdmin && <SpeedDialComponent actions={dialOptions} />}
          </>
        )}

        {currentTab === 'contracts' && <ContractsRoute />}

        {currentTab === 'wallets' && <WalletsRoute />}

        {currentTab === 'products' && <ProductsRoute />}


        {currentTab === 'taxes' && <TaxesRoute />}
      </Paper>
    </>
  );
};

export default InvoicesRoute;
