import React, { useMemo, useState } from 'react';
import Paper from '@mui/material/Paper';
import { useMutation, useQuery } from '@apollo/client';
import { useNavigate } from 'react-router-dom';
import { GET_PAGINATED_PARTNERS, REMOVE_PARTNERS } from '../../gql/partners';
import usePermissions from '../../hooks/usePermissions';
import GenericTable from '../../components/genericTable';
import FormControl from '@mui/material/FormControl';
import Select, { SelectChangeEvent } from '@mui/material/Select';
import MenuItem from '@mui/material/MenuItem';

const columnsOptions = {
  id: {
    hide: true,
  },
  contracts: {
    hide: true,
  },
  companyName: {
    headerName: 'Razao Social',
  },
  tradingName: {
    headerName: 'Nome Fantasia',
  },
  contactName: {
    headerName: 'Nome do representante',
  },
  contactEmail: {
    headerName: 'E-mail do representante',
  },
  contactNumber: {
    headerName: 'Telefone do representante',
  },
  email: {
    headerName: 'E-mail',
  },
  cnpj: {
    headerName: 'CNPJ',
  },
  website: {
    headerName: 'Website',
  },
  street: {
    headerName: 'Endereço',
  },
  district: {
    headerName: 'Bairro',
  },
  zipcode: {
    headerName: 'CEP',
  },
  city: {
    headerName: 'Cidade',
  },
  telephone: {
    headerName: 'Telefone',
  },
  state: {
    headerName: 'Estado',
  },
};

const PartnersRoute = () => {
  const navigate = useNavigate();
  const { isAdmin } = usePermissions();

  const [currentField, setCurrentField] = useState('companyName');

  const { data, loading, refetch, variables } = useQuery(GET_PAGINATED_PARTNERS, {
    notifyOnNetworkStatusChange: true,
    fetchPolicy: 'network-only',
    variables: {
      pageInfo: {
        page: 1,
        limit: 30,
      },
    },
  });
  const [deletePartner] = useMutation(REMOVE_PARTNERS, {
    refetchQueries: [{ query: GET_PAGINATED_PARTNERS }],
  });

  const handleEditPartner = ({ id = '' }: { id?: string }) =>
    navigate(`/dashboard/partners/${id}`);

  const handleDeletePartner = ({ id = '' }) => deletePartner({ variables: { id } });

  const handlePaginate = (pageInfo: { limit: number; page: number }) => {
    refetch({
      ...variables,
      pageInfo: {
        ...pageInfo,
        page: pageInfo.page || 1,
      },
    });
  };

  const handleSearch = ({ quickFilterValues, field }: Record<any, any>) => {
    if (quickFilterValues) {
      const [value] = quickFilterValues;
      refetch({
        ...variables,
        pageInfo: { page: 1, limit: variables?.pageInfo.limit ?? 30 },
        // @ts-ignore
        ...(value && (field || currentField)
          ? {
              search: {
                field: field || currentField,
                value,
              },
            }
          : {}),
      });
    }
  };

  const handleChange = (event: SelectChangeEvent) => {
    const field = event.target.value;
    setCurrentField(field);
    handleSearch({ quickFilterValues: [''], field });
  };

  const handleSort = (sortModel: Record<any, any>[]) => {
    if (sortModel?.length) {
      const [{ field, sort }] = sortModel;
      refetch({
        ...variables,
        // @ts-ignore
        ...(field
          ? {
              orderBy: {
                field,
                order: sort.toUpperCase(),
              },
            }
          : {}),
        pageInfo: { page: 1, limit: variables?.pageInfo.limit ?? 30 },
      });
    }
  };

  const options = useMemo(() => {
    const result: any[] = [];
    if (!columnsOptions) return result;
    Object.keys(columnsOptions).map((key) => {
      // @ts-ignore
      const value = columnsOptions[key];
      if (value.headerName) {
        result.push({
          value: key,
          label: value.headerName,
        });
      }
      return null;
    });

    return result;
  }, [columnsOptions]);

  return (
    <Paper sx={{ width: '100%', mb: 2 }}>
      <GenericTable
        data={data?.getPaginatedPartners?.items}
        loading={loading}
        columnOptions={columnsOptions}
        onEdit={handleEditPartner}
        onDelete={handleDeletePartner}
        hideDeleteButton={!isAdmin}
        filterMode="server"
        onFilter={handleSearch}
        onSort={handleSort}
        pageInfo={data?.getPaginatedPartners?.meta}
        onPaginate={handlePaginate}
        searchInToolbar={
          <FormControl variant="standard" sx={{ m: 0, minWidth: 120, marginTop: '-5px' }}>
            <Select
              fullWidth
              labelId="field"
              value={currentField}
              onChange={handleChange}
              label="Campo"
            >
              {options.map((option) => (
                <MenuItem key={`dropdown_search_${option.value}`} value={option.value}>
                  {option.label}
                </MenuItem>
              ))}
            </Select>
          </FormControl>
        }
      />
    </Paper>
  );
};

export default PartnersRoute;
