import React, { useEffect } from 'react';
import { useLazyQuery, useMutation, useQuery } from '@apollo/client';
import { useNavigate, useParams, useLocation } from 'react-router-dom';
import { useFormik } from 'formik';
import userSchema from './schema';
import Grid from '@mui/material/Grid';
import FormControl from '@mui/material/FormControl';
import Avatar from '@mui/material/Avatar';
import InputLabel from '@mui/material/InputLabel';
import Select from '@mui/material/Select';
import TextField from '@mui/material/TextField';
import MenuItem from '@mui/material/MenuItem';
import { userRolesMap } from '../../../utils/forms';
import { GET_ALL_PARTNERS } from '../../../gql/partners';
import {
  CREATE_USER,
  GET_ALL_USERS,
  RESET_PASSWORD,
  GET_USER_BY_ID,
  UPDATE_USER,
} from '../../../gql/users';
import FormHelperText from '@mui/material/FormHelperText';
import Button from '@mui/material/Button';
import useSnackbar from '../../../hooks/useSnackbar';
import { getTenantId } from '../../../services/tenancy';
import Box from '@mui/material/Box';
import InputMask from 'react-input-mask';
import Header from '../../../components/header';
import { spacing } from '../../../theme/themeCreator';
import CustomDialog from '../../../components/dialog';

interface UserFormProps {
  uid?: string;
}

const UserForm: React.FC<UserFormProps> = ({ uid }) => {
  const { id: paramsId } = useParams();
  const id = uid ?? paramsId;
  const location = useLocation();
  const isCreate = location.pathname.endsWith('/create');
  const snackbar = useSnackbar();
  const navigate = useNavigate();
  const [mutateUser] = useMutation(isCreate ? CREATE_USER : UPDATE_USER, {
    refetchQueries: [{ query: GET_ALL_USERS }],
  });
  const { data: partnerData } = useQuery(GET_ALL_PARTNERS);
  const [getCurrentUser, { data }] = useLazyQuery(GET_USER_BY_ID, {
    fetchPolicy: 'no-cache',
  });
  const [displayEditModal, setDisplayEditModal] = React.useState(false);
  const [resetPassword] = useMutation(RESET_PASSWORD, { fetchPolicy: 'no-cache' });
  const [hasResetedPassword, setHasResetedPassword] = React.useState(false);

  const handleCloseModal = () => {
    setDisplayEditModal(false);
  };
  const handleOpenModal = () => {
    setDisplayEditModal(true);
  };

  const navigateBack = () => navigate('/dashboard/users');

  const handleSubmit = (values: Record<any, any>) => {
    snackbar.setGlobalLoading(true);
    snackbar.sendAlert({ level: 'info', message: 'Salvando Dados...' });
    const input: Record<any, any> = { tenantId: getTenantId() };
    Object.keys(userSchema.fields).forEach((key) => {
      if (Object.hasOwn(values, key)) input[key] = values[key];
    });
    mutateUser({ variables: { input } })
      .then(() => {
        snackbar.sendAlert({ level: 'success', message: 'Dados salvos com sucesso' });
        navigateBack();
      })
      .catch((er) => {
        snackbar.sendAlert({
          level: 'error',
          message: `Erro Salvando dados: ${JSON.stringify(er)}`,
        });
        console.error(er);
      })
      .finally(() => {
        snackbar.setGlobalLoading(false);
      });
  };

  const formik = useFormik({
    initialValues: data?.getUserById
      ? {
          ...data.getUserById,
          partnerId: data.getUserById?.partner?.id,
        }
      : {
          role: 0,
        },
    validationSchema: userSchema,
    onSubmit: handleSubmit,
    enableReinitialize: true,
  });

  const handleResetPassword = () => {
    resetPassword({ variables: { input: { email: data?.getUserById?.email } } })
      .then(() => {
        setHasResetedPassword(true);
        snackbar.sendAlert({
          level: 'success',
          message: 'Instruções enviadas para o seu email',
        });
      })
      .catch((er) => {
        snackbar.sendAlert({
          level: 'error',
          message: `Erro ao resetar senha: ${JSON.stringify(er)}`,
        });
      });
  };

  useEffect(() => {
    if (!isCreate) getCurrentUser({ variables: { id } });
  }, [isCreate, getCurrentUser, id]);

  return (
    <form onSubmit={formik.handleSubmit}>
      <Grid container justifyContent="space-between" alignItems="center">
        <Grid item>{isCreate && <Header title="Criar Usuário" />}</Grid>
        <Grid item>
          <Button size="large" onClick={navigateBack} sx={{ mr: 2 }}>
            Cancelar
          </Button>
          {isCreate ? (
            <Button variant="contained" size="large" type="submit">
              Salvar
            </Button>
          ) : (
            <Button
              onClick={handleOpenModal}
              variant="contained"
              size="large"
              type="button"
            >
              Salvar
            </Button>
          )}
        </Grid>
      </Grid>
      <Grid container spacing={4} justifyContent="center">
        <CustomDialog
          open={displayEditModal}
          onClose={handleCloseModal}
          title="Editar usuário"
          content="Você tem certeza que gostaria de salvar essa edição?"
          actions={[
            {
              onClick: () => formik.handleSubmit,
              title: 'Salvar',
            },
          ]}
        />
        <Grid container spacing={4}>
          <Grid xs={12} item mt={5}>
            <Box display="flex" justifyContent="center" marginBottom={-6}>
              <Avatar
                style={{
                  width: 100,
                  height: 100,
                  cursor: 'pointer',
                }}
              >
                {formik.values.firstName || formik.values.lastName
                  ? `${formik.values.firstName ? formik.values.firstName[0] : ''} ${
                      formik.values.lastName ? formik.values.lastName[0] : ''
                    }`
                  : ''}
              </Avatar>
            </Box>
          </Grid>
          <Grid xs={12} item />
        </Grid>
        <Grid
          container
          item
          xs={12}
          justifyContent="center"
          alignItems="center"
          flexDirection="column"
          gap={2}
        >
          <TextField
            sx={{ width: spacing.xxl }}
            margin="normal"
            id="firstName"
            label="Nome"
            name="firstName"
            value={formik.values.firstName ?? ''}
            onChange={formik.handleChange}
            error={formik.touched.firstName && Boolean(formik.errors.firstName)}
            helperText={formik.touched.firstName && formik.errors.firstName}
            variant="standard"
          />
          <TextField
            sx={{ width: spacing.xxl }}
            margin="normal"
            id="lastName"
            label="Sobrenome"
            name="lastName"
            value={formik.values.lastName ?? ''}
            onChange={formik.handleChange}
            error={formik.touched.lastName && Boolean(formik.errors.lastName)}
            helperText={formik.touched.lastName && formik.errors.lastName}
            variant="standard"
          />
          <FormControl sx={{ width: '15%' }} variant="standard">
            <InputMask
              mask="999.999.999.99"
              value={formik.values.cpf ?? ''}
              disabled={false}
              onChange={({ target }) => {
                formik.setFieldValue('cpf', String(target.value.replace(/[^\d]/g, '')));
              }}
            >
              <TextField
                autoFocus
                placeholder="00.000.000/0000-00"
                error={Boolean(formik.errors.cpf)}
                name="cpf"
                id="cpf-mask"
                variant="standard"
                label="cpf"
                helperText={formik.errors.cpf}
              />
            </InputMask>
          </FormControl>
          <TextField
            margin="normal"
            sx={{ width: spacing.xxl }}
            id="email"
            label="Email"
            name="email"
            value={formik.values.email ?? ''}
            onChange={formik.handleChange}
            error={formik.touched.email && Boolean(formik.errors.email)}
            helperText={formik.touched.email && formik.errors.email}
            variant="standard"
          />
          <FormControl sx={{ width: spacing.xxl }}>
            <InputLabel id="partnerId" sx={{ marginLeft: -1.8 }}>
              Parceiro
            </InputLabel>
            <Select
              required
              variant="standard"
              labelId="partnerId"
              id="partnerId"
              value={formik.values.partnerId ?? ''}
              label="partnerId"
              name="partnerId"
              onChange={formik.handleChange}
              error={formik.touched.partnerId && Boolean(formik.errors.partnerId)}
            >
              {partnerData?.getAllPartners?.map((item: Record<any, any>) => (
                <MenuItem value={item.id} key={item.id}>
                  {item.tradingName}
                </MenuItem>
              ))}
            </Select>
            <FormHelperText>{formik.errors.partnerId}</FormHelperText>
          </FormControl>
          <FormControl sx={{ width: spacing.xxl }}>
            <InputLabel id="Grupo">Grupo</InputLabel>
            <Select
              required
              labelId="role"
              id="role"
              value={formik.values.role ?? 0}
              label="Role"
              name="role"
              variant="standard"
              onChange={formik.handleChange}
              error={formik.touched.role && Boolean(formik.errors.role)}
            >
              {userRolesMap.map((item, index) => (
                <MenuItem key={item} value={index}>
                  {item}
                </MenuItem>
              ))}
            </Select>
          </FormControl>
          <TextField
            sx={{ width: spacing.xxl }}
            required
            margin="normal"
            type="password"
            id="password"
            label="Senha"
            name="password"
            value={formik.values.password ?? ''}
            onChange={formik.handleChange}
            error={formik.touched.password && Boolean(formik.errors.password)}
            helperText={formik.touched.password && formik.errors.password}
            variant="standard"
          />
        </Grid>
      </Grid>
      <Grid container justifyContent="flex-start">
        <Button
          variant="outlined"
          size="small"
          type="button"
          disabled={hasResetedPassword}
          onClick={handleResetPassword}
        >
          Resetar senha
        </Button>
      </Grid>
    </form>
  );
};

export default UserForm;
