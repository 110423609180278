import React, { useEffect, useState } from 'react';
import { useLazyQuery, useQuery } from '@apollo/client';
import { useParams, useNavigate } from 'react-router-dom';
import Grid from '@mui/material/Grid';
import Avatar from '@mui/material/Avatar';
import Typography from '@mui/material/Typography';
import Paper from '@mui/material/Paper';
import { GET_ALL_PARTNERS } from '../../../gql/partners';
import { GET_USER_BY_ID } from '../../../gql/users';
import { GridFlex } from '../style';
import ButtonActionHeader from '../../../components/HeaderButton/style';
import Header from '../../../components/header';

interface UserFormProps {
  uid?: string;
}

const UserView: React.FC<UserFormProps> = ({ uid }) => {
  const navigate = useNavigate();
  const { id: routerId } = useParams();
  const userId = uid ?? routerId;

  const isCreate = userId === 'create';

  const { data: partnerData } = useQuery(GET_ALL_PARTNERS);
  const [getCurrentUser, { data, error }] = useLazyQuery(GET_USER_BY_ID, {
    fetchPolicy: 'no-cache',
  });

  const [user, setUser] = useState<any>(null);

  useEffect(() => {
    if (!isCreate && userId) {
      getCurrentUser({ variables: { id: userId } });
    }
  }, [isCreate, getCurrentUser, userId]);

  useEffect(() => {
    if (data?.getUserById) {
      setUser({
        ...data.getUserById,
        partnerId: data.getUserById?.partner?.id,
      });
    } else {
      setUser({ role: 0 });
    }
  }, [data]);

  if (error) console.error(error);

  const partnerName =
    partnerData?.getAllPartners?.find(
      (item: Record<string, any>) => item.id === user?.partnerId
    )?.tradingName || 'Não encontrado';

  const handleEditUser = () => {
    navigate('edit');
  };

  return (
    <Grid>
      <Grid
        sx={{
          display: 'flex',
          justifyContent: 'space-between',
          alignItems: 'center',
          marginBottom: 4,
        }}
      >
        <Header title="Perfil" />
        <ButtonActionHeader onClick={handleEditUser}>Editar perfil</ButtonActionHeader>
      </Grid>
      <Paper
        sx={{
          padding: 8,
          borderRadius: 2,
          maxWidth: 'auto',
          margin: 'auto',
        }}
      >
        <Grid container spacing={4}>
          <Grid item>
            <Avatar
              sx={{
                width: 80,
                height: 80,
                background: '#95FFB3',
                color: '#000',
                border: '1px solid black',
              }}
            >
              {user?.firstName || user?.lastName
                ? `${user?.firstName ? user.firstName[0] : ''}${
                    user?.lastName ? user.lastName[0] : ''
                  }`
                : ''}
            </Avatar>
          </Grid>

          <Grid item xs>
            <Typography variant="h5" fontWeight="bold">
              {user?.firstName} {user?.lastName}
            </Typography>

            <Grid container flexDirection="column" spacing={2} sx={{ marginTop: 1 }}>
              <Grid item>
                <GridFlex>
                  <Typography fontSize={20} fontWeight="bold">
                    Email:
                  </Typography>
                  <Typography fontSize={20}>{user?.email || 'Não encontrado'}</Typography>
                </GridFlex>
              </Grid>
              <Grid item>
                <GridFlex>
                  <Typography fontWeight="bold" fontSize={20}>
                    Telefone:
                  </Typography>
                  <Typography fontSize={20}>{user?.cpf || 'Não encontrado'}</Typography>
                </GridFlex>
              </Grid>
              <Grid item>
                <GridFlex>
                  <Typography fontWeight="bold" fontSize={20}>
                    Endereço:
                  </Typography>
                  <Typography fontSize={20}>{partnerName || 'Não encontrado'}</Typography>
                </GridFlex>
              </Grid>
            </Grid>
          </Grid>
        </Grid>
      </Paper>
    </Grid>
  );
};

export default UserView;
