import * as React from 'react';
import Box from '@mui/material/Box';
import MenuItem from '@mui/material/MenuItem';
import Select from '@mui/material/Select';
import Chip from '@mui/material/Chip';
import { FormControl, InputLabel } from '@mui/material';
import type { SelectProps } from '@mui/material/Select';
import { spacing } from '../../theme/themeCreator';

const ITEM_HEIGHT = 48;
const ITEM_PADDING_TOP = 8;
const MenuProps = {
  PaperProps: {
    style: {
      maxHeight: ITEM_HEIGHT * 4.5 + ITEM_PADDING_TOP,
      width: 250,
    },
  },
};

const toHumanReadableDate = (date: number) => `dia ${date < 10 ? '0' : ''}${date}`;

const dueDate = new Array(28)
  .fill('')
  .map((_, index) => ({ value: index + 1, label: toHumanReadableDate(index + 1) }));

const SelectDueDate = (props: SelectProps) => (
  <FormControl variant="standard" fullWidth sx={{ mt: 2 }}>
    <InputLabel>Dia de pagamento</InputLabel>
    <Select
      {...props}
      multiple
      label="Dia de pagamento"
      renderValue={(selected: any) => (
        <Box sx={{ display: 'flex', flexWrap: 'wrap', gap: spacing.sm}}>
          {selected.map((value: any) => (
            <Chip key={value} label={toHumanReadableDate(value)} />
          ))}
        </Box>
      )}
      MenuProps={MenuProps}
    >
      {dueDate.map((day) => (
        <MenuItem key={day.value} value={day.value}>
          {day.label}
        </MenuItem>
      ))}
    </Select>
  </FormControl>
);

export default SelectDueDate;
