import MuiButtonGroup from '@mui/material/ButtonGroup';
import styled from 'styled-components';
import { fontSizes } from '../../theme/themeCreator';

const ButtonGroup = styled(MuiButtonGroup)`
  height: 35px;
  box-shadow: none;

  button {
    font-size: ${fontSizes.sm};
    color: #9a9595;
    background-color: transparent;
    border-left: none !important;
    border-right: none !important;
    border-bottom-left-radius: 0;
    border-bottom-right-radius: 0;
  }

  .button-group-selected {
    border-bottom: 3px solid ${({ theme }) => theme?.palette?.primary.main} !important;
    color: #000000;
  }
`;

export default ButtonGroup;
