import React, { useEffect, useMemo, useState } from 'react';
import { useFormik } from 'formik';
import { useMutation, useLazyQuery, useQuery } from '@apollo/client';
import { useNavigate, useParams } from 'react-router-dom';
import Dialog from '@mui/material/Dialog';
import Paper from '@mui/material/Paper';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import Grid from '@mui/material/Grid';
import DialogContentText from '@mui/material/DialogContentText';
import DialogTitle from '@mui/material/DialogTitle';
import TextField from '@mui/material/TextField';
import Button from '@mui/material/Button';
import FormControl from '@mui/material/FormControl';
import { InputLabel, Select } from '@mui/material';
import MenuItem from '@mui/material/MenuItem';
import FormHelperText from '@mui/material/FormHelperText';
import Autocomplete from '@mui/material/Autocomplete';
import validationSchema from './schema';
import {
  GET_ALL_WALLETS,
  GET_WALLET_BY_ID,
  UPDATE_WALLET,
  CREATE_WALLET,
  GET_ALL_WALLETS_IDS,
} from '../../../gql/wallets';
import Header from '../../../components/header';
import useSnackbar from '../../../hooks/useSnackbar';
import { getTenantId } from '../../../services/tenancy';
import { GET_ALL_PARTNERS } from '../../../gql/partners';
import { GET_ALL_MANUFACTURERS } from '../../../gql/manufacturers';
import { GET_ACRONIS_TENANTS } from '../../../gql/acronis';
import DeleteIcon from '@mui/icons-material/Delete';
import usePermissions from '../../../hooks/usePermissions';

const WalletForm = () => {
  const { id } = useParams();
  const isCreate = id === 'create';
  const snackbar = useSnackbar();
  const navigate = useNavigate();
  const { data: partnerData } = useQuery(GET_ALL_PARTNERS);
  const { data: walltetIds } = useQuery(GET_ALL_WALLETS_IDS);
  const { data: manufacturersData, loading: loadingManufacturers } =
    useQuery(GET_ALL_MANUFACTURERS);

  const { data: acronisData, loading: loadingAcronisIds } = useQuery(GET_ACRONIS_TENANTS);

  const acronisId = useMemo(() => {
    const acronisManufacturer = manufacturersData?.getAllManufacturers?.find(
      (manufacturer: any) => manufacturer.name.toLowerCase().includes('acronis')
    );
    if (acronisManufacturer) return acronisManufacturer.id;
    return null;
  }, [loadingManufacturers]);

  const [mutateWallet] = useMutation(isCreate ? CREATE_WALLET : UPDATE_WALLET, {
    refetchQueries: [{ query: GET_ALL_WALLETS }],
  });
  const [getCurrentWallet, { data }] = useLazyQuery(GET_WALLET_BY_ID, {
    fetchPolicy: 'no-cache',
  });

  const [showModal, setShowModal] = useState(false);
  const [autoCompleteIndex, setAutocompleteIndex] = useState<number | undefined>(
    undefined
  );

  const navigateBack = () => navigate('/dashboard/invoices');

  const handleSubmit = (values: Record<any, any>) => {
    snackbar.setGlobalLoading(true);
    snackbar.sendAlert({ level: 'info', message: 'Salvando Dados...' });

    const input: Record<string, any> = { tenantId: getTenantId() };
    Object.keys(validationSchema.fields).map((key) => {
      if (Object.hasOwn(values, key)) input[key] = values[key];
      return null;
    });

    const data = input.data.map((current: any) => {
      if (!current.description) {
        current.description = 'no desc';
      }
      return current;
    });

    mutateWallet({ variables: { input: { ...input, data } } })
      .then(() =>
        snackbar.sendAlert({ level: 'success', message: 'Dados salvos com sucesso ' })
      )
      .catch((er) => {
        snackbar.sendAlert({ level: 'error', message: 'Erro Salvando dados' });
        console.error(er);
      })
      .finally(() => {
        snackbar.setGlobalLoading(false);
        navigateBack();
      });
  };

  const formik = useFormik({
    initialValues: data?.getWalletById
      ? {
          ...data.getWalletById,
          ...(data.getWalletById?.partner?.id
            ? { partnerId: data.getWalletById.partner.id }
            : {}),
        }
      : {
          data: [],
        },
    validationSchema,
    onSubmit: handleSubmit,
    enableReinitialize: true,
  });

  const addCredential = () => {
    const { data } = formik.values;
    formik.setFieldValue('data', [...data, {}]);
  };

  const removeCrendential = (resourceIndex: number) => () => {
    const { data } = formik.values;
    const newData = [...data];
    newData.splice(resourceIndex, 1);
    formik.setFieldValue('data', [...newData]);
  };

  const hideAutoComplete = () => {
    setShowModal(false);
    setAutocompleteIndex(undefined);
  };

  const handleAutoComplete = (index: number) => () => {
    setShowModal(true);
    setAutocompleteIndex(index);
  };
  const sortPartner = (partners: any) =>
    partners
      .slice()
      .sort(compare)
      .map((item: Record<any, any>) => (
        <MenuItem value={item.id} key={item.id}>
          {item.tradingName}
        </MenuItem>
      ));
  const compare = (a: any, b: any) => {
    if (a.tradingName < b.tradingName) {
      return -1;
    }
    if (a.tradingName > b.tradingName) {
      return 1;
    }
    return 0;
  };

  const handleChangeAutoComplete = (_: unknown, value: Record<string, string>) => {
    if (autoCompleteIndex !== undefined) {
      const { data } = formik.values;
      const settings = {
        key: 'acronisId',
        value: value.id,
        namespace: value.region,
        description: value.name,
      };

      const newData = [...data];
      newData[autoCompleteIndex] = { ...newData[autoCompleteIndex], ...settings };
      formik.setFieldValue('data', [...newData]);
    }
    hideAutoComplete();
  };

  useEffect(() => {
    if (!isCreate) getCurrentWallet({ variables: { id } });
  }, [isCreate]);

  const { isAdmin } = usePermissions();
  const adminPermissions = isAdmin;

  return (
    <>
      <Dialog open={showModal} onClose={hideAutoComplete}>
        <DialogTitle>Acronis Tenant Auto-complete</DialogTitle>
        <DialogContent>
          <DialogContentText>
            Selecione abaixo um parceiro dos servidores acronis
          </DialogContentText>
          <Autocomplete
            onChange={handleChangeAutoComplete}
            getOptionLabel={(option: any) => option.name}
            loading={loadingAcronisIds}
            options={acronisData?.getAcronisIds ?? []}
            sx={{ width: 300 }}
            renderInput={(params: any) => <TextField {...params} label="Parceiro" />}
          />
        </DialogContent>
        <DialogActions>
          <Button onClick={hideAutoComplete}>Cancelar</Button>
        </DialogActions>
      </Dialog>

      <Header title={`${isCreate ? 'Criar' : 'Editar'} Credencial`} />
      <Paper sx={{ width: '100%', mb: 2, p: 2 }}>
        <form onSubmit={formik.handleSubmit}>
          <Grid container spacing={4}>
            <Grid container item spacing={2}>
              <Grid item xs={12}>
                <FormControl fullWidth>
                  <InputLabel id="partnerId">Parceiro</InputLabel>
                  <Select
                    labelId="partnerId"
                    id="partnerId"
                    value={formik.values.partnerId ?? ''}
                    label="partnerId"
                    name="partnerId"
                    variant="standard"
                    disabled={!adminPermissions}
                    onChange={formik.handleChange}
                    error={formik.touched.partnerId && Boolean(formik.errors.partnerId)}
                    MenuProps={{
                      anchorOrigin: {
                        vertical: 'bottom',
                        horizontal: 'left',
                      },
                      transformOrigin: {
                        vertical: 'top',
                        horizontal: 'left',
                      },
                      classes: { paper: 'menu-paper' },
                      MenuListProps: {
                        style: { maxHeight: 192, overflow: 'auto' },
                      },
                      style: { width: 200 },
                    }}
                  >
                    {walltetIds &&
                      partnerData &&
                      sortPartner(
                        partnerData.getAllPartners.filter((p: any) =>
                          isCreate
                            ? !walltetIds.getAllWallets.find(
                                (w: any) => w.partner.id === p.id
                              )
                            : p
                        )
                      )}
                  </Select>
                  <FormHelperText>{formik.errors.partnerId}</FormHelperText>
                </FormControl>
              </Grid>
              {formik.values.data?.map((item: any, index: number) => (
                <Grid
                  item
                  xs={12}
                  key={`data_value_${index}`}
                  container
                  spacing={2}
                  display="flex"
                  alignItems="flex-end"
                >
                  <Grid item xs={3}>
                    <FormControl fullWidth>
                      <InputLabel>Fabricante</InputLabel>
                      <Select
                        labelId="manufacturer"
                        id="manufacturer"
                        value={formik.values.data?.[index]?.manufacturerId ?? ''}
                        label="manufacturer"
                        name={`data[${index}].manufacturerId`}
                        variant="standard"
                        onChange={formik.handleChange}
                        disabled={!adminPermissions}
                        error={
                          formik.touched.manufacturer &&
                          Boolean(formik.errors.manufacturer)
                        }
                      >
                        {manufacturersData?.getAllManufacturers?.map(
                          (item: Record<any, any>) => (
                            <MenuItem value={item.id} key={item.id}>
                              {item.name}
                            </MenuItem>
                          )
                        )}
                      </Select>
                    </FormControl>
                  </Grid>
                  <Grid item xs={3}>
                    <TextField
                      fullWidth
                      autoFocus
                      variant="standard"
                      name={`data[${index}].namespace`}
                      label="namespace (Region)"
                      value={formik.values.data[index]?.namespace ?? ''}
                      onChange={formik.handleChange}
                      error={Boolean(formik.errors.data)}
                      helperText={formik.errors.data}
                      disabled={!adminPermissions}
                    />
                  </Grid>
                  <Grid item xs={3}>
                    <TextField
                      fullWidth
                      autoFocus
                      variant="standard"
                      name={`data[${index}].key`}
                      label="Chave"
                      value={formik.values.data[index]?.key ?? ''}
                      onChange={formik.handleChange}
                      error={Boolean(formik.errors.data)}
                      helperText={formik.errors.data}
                      disabled={!adminPermissions}
                    />
                  </Grid>
                  <Grid item xs={2}>
                    <TextField
                      fullWidth
                      autoFocus
                      variant="standard"
                      name={`data[${index}].value`}
                      label="Valor"
                      value={formik.values.data[index]?.value ?? ''}
                      onChange={formik.handleChange}
                      error={Boolean(formik.errors.data)}
                      helperText={formik.errors.data}
                      disabled={!adminPermissions}
                    />
                  </Grid>
                  {formik.values.data?.[index]?.manufacturerId === acronisId &&
                    !formik.values.data?.[index]?.namespace && (
                      <Grid item>
                        <Button onClick={handleAutoComplete(index)} variant="outlined">
                          Auto-completar
                        </Button>
                      </Grid>
                    )}
                  <Grid marginBottom={-1} xs={1}>
                    {adminPermissions ? (
                      <Button onClick={removeCrendential(index)}>
                        <DeleteIcon />
                      </Button>
                    ) : null}
                  </Grid>
                </Grid>
              ))}

              <Grid item xs={12}>
                {adminPermissions ? (
                  <Button size="large" onClick={addCredential}>
                    Adicionar Credencial
                  </Button>
                ) : null}
              </Grid>
            </Grid>
            <Grid item container spacing={2} xs={12} justifyContent="flex-end">
              <Grid item>
                <Button size="large" onClick={navigateBack}>
                  Cancelar
                </Button>
              </Grid>
              <Grid item>
                {adminPermissions ? (
                  <Button variant="contained" size="large" type="submit">
                    Salvar
                  </Button>
                ) : null}
              </Grid>
            </Grid>
          </Grid>
        </form>
      </Paper>
    </>
  );
};

export default WalletForm;
