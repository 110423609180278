import * as React from 'react';
import Box from '@mui/material/Box';
import DeleteIcon from '@mui/icons-material/Delete';
import ContentCopyIcon from '@mui/icons-material/ContentCopy';
import CheckIcon from '@mui/icons-material/Check';
import VisibilityIcon from '@mui/icons-material/Visibility';
import Button from '@mui/material/Button';
import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import DialogContentText from '@mui/material/DialogContentText';
import DialogTitle from '@mui/material/DialogTitle';
import IconButton from '@mui/material/IconButton';
import Tooltip from '@mui/material/Tooltip';
import Snackbar from '@mui/material/Snackbar';
import Alert from '@mui/material/Alert';

import {
  DataGrid,
  GridActionsCellItem,
  GridColumnVisibilityModel,
  GridPaginationModel,
  GridRowParams,
  GridToolbarColumnsButton,
  GridToolbarContainer,
  GridToolbarDensitySelector,
  GridToolbarExport,
  GridToolbarFilterButton,
  GridToolbarQuickFilter,
  GridRowSelectionModel,
} from '@mui/x-data-grid';
import { ReactElement } from 'react';
import { GridFilterModel } from '@mui/x-data-grid/models/gridFilterModel';
import { GridCallbackDetails } from '@mui/x-data-grid/models/api';
import { GridSortModel } from '@mui/x-data-grid/models/gridSortModel';
import { spacing } from '../../theme/themeCreator';

interface GenericTableProps {
  data: Record<any, any>[];
  dense?: boolean;
  toolbar?: {
    [key: string]: boolean;
  };
  loading?: boolean;
  columnOptions?: Record<any, any>;
  onEdit?: (dataRow: Record<any, any>) => void;
  onDelete?: (dataRow: Record<any, any>) => void;
  customOperations?: any[];
  hideDeleteButton?: boolean;
  filterMode?: 'client' | 'server';
  onFilter?: (model: GridFilterModel, details: GridCallbackDetails<'filter'>) => void;
  onSort?: (model: GridSortModel, details: GridCallbackDetails) => void;
  searchInToolbar?: any;
  onPaginate?: (pageInfo: { limit: number; page: number }) => void;
  pageInfo?: {
    totalItems: number;
    currentPage: number;
    itemsPerPage: number;
  };
  hide?: boolean;
}

const SearchToolBar = () => (
  <Box>
    <GridToolbarQuickFilter
      quickFilterParser={(searchInput: string) =>
        searchInput
          .split(',')
          .map((value) => value.trim())
          .filter((value) => value !== '')
      }
    />
  </Box>
);

const GenericTable = ({
  data = [],
  columnOptions = {},
  loading,
  onEdit,
  onDelete,
  customOperations,
  toolbar = {
    search: true,
    columns: true,
    filter: true,
    density: false,
    expt: true,
  },
  dense = false,
  filterMode = 'client',
  onFilter,
  onSort,
  searchInToolbar,
  onPaginate,
  pageInfo,
}: GenericTableProps) => {
  const [displayDeleteModal, setDisplayDeleteModal] = React.useState(false);
  const [selectedRows, setSelectedRows] = React.useState<(string | number)[]>([]);
  const [rowsToDelete, setRowsToDelete] = React.useState<Record<string, any>[]>([]);

  const [sortModel] = React.useState<GridSortModel>([]);

  const [paginationModel, setPaginationModel] = React.useState({
    page: 1,
    pageSize: 30,
  });

  const [copiedCell, setCopiedCell] = React.useState<{
    field: string;
    id: string | number;
  } | null>(null);

  const [showCopyMessage, setShowCopyMessage] = React.useState(false);

  const handlePagination = (newPage: GridPaginationModel) => {
    onPaginate?.({
      page: newPage.page,
      limit: newPage.pageSize,
    });
    setPaginationModel(newPage);
  };

  const [rowCountState, setRowCountState] = React.useState(pageInfo?.totalItems || 0);

  React.useEffect(() => {
    setRowCountState((prevRowCountState) =>
      pageInfo?.totalItems !== undefined ? pageInfo?.totalItems : prevRowCountState
    );
  }, [pageInfo?.totalItems, setRowCountState]);

  const [localHiddenFields, setLocalHiddenFields] =
    React.useState<GridColumnVisibilityModel>({});

  const handleSelectionModelChange = (newSelection: GridRowSelectionModel) => {
    setSelectedRows(newSelection as (string | number)[]);
  };

  const handleCopyToClipboard = (text: string, field: string, id: string | number) => {
    navigator.clipboard.writeText(text.toString());
    setCopiedCell({ field, id });
    setShowCopyMessage(true);
    setTimeout(() => {
      setCopiedCell(null);
      setShowCopyMessage(false);
    }, 2000);
  };

  const columns = React.useMemo(() => {
    if (data && data.length > 0) {
      return [
        ...Object.keys(data[0]).map((field) => ({
          field,
          flex: 1,
          renderCell: (params: any) => (
            <Box
              sx={{
                display: 'flex',
                alignItems: 'center',
                width: '100%',
                padding: '4px 8px',
                borderRadius: '4px',
                transition: 'all 0.2s ease-in-out',
                position: 'relative',
                '&:hover': {
                  backgroundColor: 'rgba(0, 178, 82, 0.04)',
                  '& .copy-icon': {
                    opacity: 1,
                  },
                  '& .cell-content': {
                    color: '#00B252',
                  },
                },
              }}
            >
              <Box
                className="cell-content"
                sx={{
                  transition: 'color 0.2s ease-in-out',
                  color:
                    copiedCell?.field === field && copiedCell?.id === params.row.id
                      ? '#00B252'
                      : 'inherit',
                  flex: 1,
                  overflow: 'hidden',
                  textOverflow: 'ellipsis',
                  whiteSpace: 'nowrap',
                  paddingRight: '24px',
                }}
              >
                {params.value}
              </Box>
              <Tooltip
                title={
                  copiedCell?.field === field && copiedCell?.id === params.row.id
                    ? 'Copiado!'
                    : 'Copiar'
                }
              >
                <IconButton
                  size="small"
                  className="copy-icon"
                  onClick={(e) => {
                    e.stopPropagation();
                    handleCopyToClipboard(params.value, field, params.row.id);
                  }}
                  sx={{
                    opacity: 0,
                    transition: 'all 0.2s ease-in-out',
                    color:
                      copiedCell?.field === field && copiedCell?.id === params.row.id
                        ? '#00B252'
                        : 'inherit',
                    position: 'absolute',
                    right: '4px',
                    top: '50%',
                    transform: 'translateY(-50%)',
                    padding: '2px',
                    '& .MuiSvgIcon-root': {
                      fontSize: '16px',
                    },
                  }}
                >
                  {copiedCell?.field === field && copiedCell?.id === params.row.id ? (
                    <CheckIcon fontSize="small" />
                  ) : (
                    <ContentCopyIcon fontSize="small" />
                  )}
                </IconButton>
              </Tooltip>
            </Box>
          ),
          ...(columnOptions?.[field] ?? {}),
        })),
        {
          field: 'ações',
          type: 'actions',
          getActions: (params: GridRowParams) =>
            customOperations?.map((c) => (
              <GridActionsCellItem
                icon={c.icon as ReactElement}
                onClick={() => {
                  c.onClick(params);
                }}
                showInMenu
                label={c.label as string}
              />
            )) ?? [],
        },
      ];
    }
    return [];
  }, [data, customOperations, copiedCell]);

  const filteredColumns = React.useMemo(
    () => columns.filter((col) => col.field !== 'ações'),
    [columns]
  );

  const hiddenColumns = React.useMemo(() => {
    const hiddenFields: GridColumnVisibilityModel = {};

    columns.map((item) => {
      if (item?.hide) hiddenFields[item.field] = false;
      return null;
    });
    return {
      ...hiddenFields,
      ...localHiddenFields,
    };
  }, [columns, localHiddenFields]);

  const handleCloseModal = () => {
    setRowsToDelete([]);
    setDisplayDeleteModal(false);
  };

  const handleDelete = () => {
    if (rowsToDelete.length > 0) {
      rowsToDelete.forEach((row) => {
        onDelete?.(row);
      });
    }
    setRowsToDelete([]);
    setSelectedRows([]);
    handleCloseModal();
  };

  const handleDeleteSelected = () => {
    const selectedRowData = data.filter((row) => {
      const rowId = row.id?.toString() || '';
      return selectedRows.some((selectedId) => selectedId.toString() === rowId);
    });
    setRowsToDelete(selectedRowData);
    setDisplayDeleteModal(true);
  };

  const customToolbar = () => (
    <Box>
      <GridToolbarContainer>
        {toolbar.search && <SearchToolBar />}
        {searchInToolbar}
        {toolbar.columns && <GridToolbarColumnsButton />}
        {toolbar.filter && <GridToolbarFilterButton />}
        {toolbar.density && <GridToolbarDensitySelector />}
        {toolbar.expt && <GridToolbarExport />}
      </GridToolbarContainer>

      {selectedRows.length > 0 && (
        <Box
          sx={{
            position: 'absolute',
            top: '85px',
            left: '32px',
            display: 'flex',
            alignItems: 'center',
            gap: spacing.xl,
            zIndex: 2,
          }}
        >
          <span style={{ color: '#00B252', fontWeight: 500 }}>
            {selectedRows.length} selecionados
          </span>
          {selectedRows.length === 1 && (
            <Button
              variant="text"
              size="small"
              sx={{
                color: '#1F1F1F',
                textTransform: 'none',
                fontWeight: 400,
                padding: '4px 8px',
                minWidth: 'auto',
              }}
              startIcon={<VisibilityIcon sx={{ fontSize: '13px' }} />}
              onClick={() => {
                const selectedRow = data.find((row) => row.id === selectedRows[0]);
                if (selectedRow) onEdit?.(selectedRow);
              }}
            >
              Visualizar
            </Button>
          )}
          <Button
            variant="text"
            size="small"
            sx={{
              color: '#1F1F1F',
              textTransform: 'none',
              fontWeight: 400,
              fontSize: '0.875rem',
              padding: '4px 8px',
              minWidth: 'auto',
            }}
            startIcon={<DeleteIcon sx={{ fontSize: '18px' }} />}
            onClick={handleDeleteSelected}
          >
            Excluir
          </Button>
        </Box>
      )}
    </Box>
  );

  return (
    <Box sx={{ width: '100%', borderRadius: 2, zIndex: 1, position: 'relative' }}>
      <Dialog
        open={displayDeleteModal}
        onClose={handleCloseModal}
        aria-labelledby="alert-dialog-title"
        aria-describedby="alert-dialog-description"
      >
        <DialogTitle id="alert-dialog-title">
          {rowsToDelete.length > 1 ? 'Deletar Múltiplas Entidades' : 'Deletar Entidade'}
        </DialogTitle>
        <DialogContent>
          <DialogContentText id="alert-dialog-description">
            {rowsToDelete.length > 1
              ? `Você tem certeza que deseja deletar ${rowsToDelete.length} entidades?`
              : 'Você tem certeza que você quer deletar esta entidade?'}
          </DialogContentText>
        </DialogContent>
        <DialogActions>
          <Button onClick={handleCloseModal}>Cancelar</Button>
          <Button onClick={handleDelete} autoFocus color="error">
            Deletar
          </Button>
        </DialogActions>
      </Dialog>

      <DataGrid
        autoHeight
        disableRowSelectionOnClick
        checkboxSelection
        density={!dense ? 'standard' : 'compact'}
        filterMode={filterMode}
        sortingMode={filterMode}
        paginationMode={filterMode}
        onRowClick={(params, event) => {
          if ((event.target as HTMLElement).closest('.MuiCheckbox-root')) {
            return;
          }
          setSelectedRows([params.row.id]);
        }}
        localeText={{
          columnsPanelTextFieldLabel: 'Encontrar Coluna',
          columnsPanelTextFieldPlaceholder: 'Título da Coluna',
          columnsPanelShowAllButton: 'Mostrar Todas',
          columnsPanelHideAllButton: 'Ocultar Todas',
          filterPanelInputLabel: 'Valor',
          filterPanelColumns: 'Colunas',
          filterOperatorStartsWith: 'Inicia Com',
          filterOperatorEndsWith: 'Termina com',
          filterOperatorIsEmpty: 'Vazio',
          filterOperatorIsNotEmpty: 'Não Vazio',
          filterOperatorContains: 'Contém',
          filterOperatorEquals: 'igual',
          toolbarExportCSV: 'Baixar em CSV',
          toolbarExportPrint: 'Imprimir',
          columnMenuUnsort: 'Filtragem',
          filterPanelInputPlaceholder: 'Filtragem...',
          filterOperatorIsAnyOf: 'Qualquer um de...',
          columnMenuSortAsc: 'Organizar por Crescente',
          columnMenuSortDesc: 'Organizar por Decrescente',
          columnMenuFilter: 'Filtro',
          columnMenuShowColumns: 'Mostrar Colunas',
          columnMenuHideColumn: 'Ocultar Colunas',
          noRowsLabel: 'Nada encontrado...',
          toolbarQuickFilterPlaceholder: 'Procurar…',
          toolbarColumns: 'Colunas',
          toolbarFilters: 'Filtro',
          toolbarDensity: 'Densidade',
          toolbarExport: 'Exportar',
        }}
        onColumnVisibilityModelChange={setLocalHiddenFields}
        columnVisibilityModel={hiddenColumns}
        sx={{
          padding: '2em',
          borderWidth: 1,
          borderStyle: 'solid',
          '& .MuiDataGrid-toolbarContainer': {
            marginBottom: '30px',
            display: 'flex',
            justifyContent: 'space-between',
            '& .MuiBox-root': {
              flex: 1,
            },
            '& .MuiButton-root:not(:first-of-type)': {
              marginLeft: 'auto',
            },
          },
          '& .MuiInputBase-root': {
            borderRadius: 2,
            padding: '10px',
          },
          '& .MuiButton-root': {
            fontWeight: 600,
            fontSize: '1.2rem',
            color: '#707070',
          },
          '& .MuiToolbar-root': {
            width: '18%',
            border: 'none',
          },
          '& .MuiDataGrid-row:hover': {
            backgroundColor: '#F3FFF7',
          },
        }}
        loading={loading}
        rows={data}
        columns={filteredColumns}
        pageSizeOptions={[10, 30, 50]}
        disableColumnMenu
        initialState={{
          pagination: { paginationModel: { pageSize: 30 } },
          sorting: {
            sortModel,
          },
          filter: {
            filterModel: {
              items: [],
            },
          },
        }}
        slots={{
          toolbar: customToolbar,
        }}
        rowSelectionModel={selectedRows}
        onRowSelectionModelChange={handleSelectionModelChange}
        slotProps={{
          toolbar: {
            showQuickFilter: true,
            quickFilterProps: { debounceMs: 500 },
          },
        }}
        {...(filterMode === 'server'
          ? {
              onFilterModelChange: onFilter,
              onSortModelChange: onSort,
              paginationModel,
              onPaginationModelChange: handlePagination,
              rowCount: rowCountState,
            }
          : {})}
      />

      <Snackbar
        open={showCopyMessage}
        autoHideDuration={2000}
        anchorOrigin={{ vertical: 'bottom', horizontal: 'center' }}
        sx={{
          '& .MuiSnackbarContent-root': {
            backgroundColor: 'rgba(0, 178, 82, 0.1)',
            color: '#00B252',
            boxShadow: 'none',
            padding: '8px 16px',
            borderRadius: '4px',
            minWidth: 'auto',
          },
        }}
      >
        <Alert
          severity="success"
          sx={{
            backgroundColor: 'transparent',
            color: '#00B252',
            '& .MuiAlert-icon': {
              color: '#00B252',
            },
          }}
        >
          Copiado para o clipboard
        </Alert>
      </Snackbar>
    </Box>
  );
};

export default GenericTable;
