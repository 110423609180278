import React, { useState } from 'react';
import LoginForm from './forms/loginForm';
import ForgotPasswordForm from './forms/forgotPasswordForm';
import { LoginContainer, LoginBox } from './styles';
import Grid from '@mui/material/Grid';
import backgroundLogin from '../../assets/images/bg-min.jpg';

const Login = () => {
  const [showReset, setShowReset] = useState<boolean>(false);

  const toggleReset = () => setShowReset(!showReset);

  return (
    <LoginContainer maxWidth="xl">
      <Grid sx={{ display: 'flex', justifyContent: 'center', alignItems: 'center' }}>
        <LoginBox>
          <Grid xs={5}>
            {showReset ? (
              <ForgotPasswordForm toggleReset={toggleReset} />
            ) : (
              <LoginForm toggleReset={toggleReset} />
            )}
          </Grid>
        </LoginBox>
      </Grid>
      <Grid
        sx={{
          backgroundImage: `url(${backgroundLogin})`,
          backgroundSize: 'cover',
          backgroundRepeat: 'no-repeat',
          backgroundPosition: 'center',
          height: '100vh',
        }}
      />
    </LoginContainer>
  );
};

export default Login;
