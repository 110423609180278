import React from 'react';
import Paper from '@mui/material/Paper';
import { useMutation, useQuery } from '@apollo/client';
import GenericTable from '../../components/genericTable';
import Header from '../../components/header';
import { useNavigate } from 'react-router-dom';
import { GET_ALL_USERS, REMOVE_USER } from '../../gql/users';
import Group from '../group/index';
import ButtonGroup from '../../components/buttonGroup';
import PartnersRoute from '../partners/index';
import useLocationName from '../../hooks/useLocationName';
import usePermissions from '../../hooks/usePermissions';
import { Role as userRoles } from '../../utils/roles';
import Box from '@mui/material/Box';
import users from '../../assets/images/users.png';
import partner from '../../assets/images/partner.png';
import groups from '../../assets/images/groups.png';
import { ButtonTopTable } from './style';
import HeaderButton from '../../components/HeaderButton/style';

const columnsOptions = {
  id: {
    hide: true,
  },
  firstName: {
    headerName: 'Nome',
  },
  lastName: {
    headerName: 'Sobrenome',
  },
  email: {
    headerName: 'E-mail',
  },
  role: {
    headerName: 'Grupo',
    valueGetter: ({ value }: Record<any, any>) => userRoles[value],
  },
  partner: {
    headerName: 'Parceiro',
    valueGetter: ({ value }: Record<any, any>) => value.tradingName,
  },
};

const tabNames: Record<string, string> = {
  users: 'Usuários',
  groups: 'Grupos',
  partners: 'Parceiros',
};

const UsersRoute = () => {
  const navigate = useNavigate();
  const currentTab = useLocationName();
  const { isAdmin } = usePermissions();

  const { data, loading } = useQuery(GET_ALL_USERS);
  const [deleteUser] = useMutation(REMOVE_USER, { refetchQueries: [GET_ALL_USERS] });

  const handleNewUser = () => navigate('create');
  const handleEditUser = ({ id = '' }: { id?: string }) => navigate(id);
  const handleDeleteUser = ({ id = '' }) => deleteUser({ variables: { id } });

  const handleChangeTabs = (newTab: React.SetStateAction<string>) => () =>
    navigate(`/dashboard/${newTab}`);

  const defaultTab = isAdmin ? currentTab : 'partners';

  return (
    <>
      <Box
        sx={{ display: 'flex', alignItems: 'center', justifyContent: 'space-between' }}
      >
        <Header title={tabNames[defaultTab]} />
        {isAdmin && (
          <HeaderButton onClick={handleNewUser} type="submit">
            {`Adicionar Novo ${tabNames[defaultTab].slice(0, -1)}`}
          </HeaderButton>
        )}
      </Box>

      <ButtonGroup variant="contained" size="large">
        {isAdmin ? (
          <>
            <Box sx={{ display: 'flex', alignItems: 'center' }}>
              <ButtonTopTable
                className={currentTab === 'users' ? 'button-group-selected' : ''}
                onClick={handleChangeTabs('users')}
              >
                <img src={users} alt="" /> Usuários
              </ButtonTopTable>
            </Box>

            <Box sx={{ display: 'flex', alignItems: 'center' }}>
              <ButtonTopTable
                className={currentTab === 'groups' ? 'button-group-selected' : ''}
                onClick={handleChangeTabs('groups')}
              >
                <img src={groups} style={{ width: '20px' }} alt="" /> Grupos
              </ButtonTopTable>
            </Box>

            <Box sx={{ display: 'flex', alignItems: 'center' }}>
              <ButtonTopTable
                className={currentTab === 'partners' ? 'button-group-selected' : ''}
                onClick={handleChangeTabs('partners')}
              >
                <img src={partner} style={{ width: '16px' }} alt="" /> Parceiros
              </ButtonTopTable>
            </Box>
          </>
        ) : null}
      </ButtonGroup>

      {defaultTab === 'users' && isAdmin && (
        <Paper sx={{ width: '100%', mb: 2 }}>
          <GenericTable
            data={data?.getAllUsers}
            loading={loading}
            columnOptions={columnsOptions}
            onEdit={handleEditUser}
            onDelete={handleDeleteUser}
          />
        </Paper>
      )}

      {defaultTab === 'partners' && <PartnersRoute />}

      {defaultTab === 'groups' && (
        <Paper sx={{ width: '100%', mb: 2 }}>
          <Group />
        </Paper>
      )}
    </>
  );
};

export default UsersRoute;
