import React, { useState, useEffect } from 'react';
import Paper from '@mui/material/Paper';
import { useNavigate } from 'react-router-dom';
import { useQuery, useMutation } from '@apollo/client';
import { useFormik } from 'formik';
import Grid from '@mui/material/Grid';
import TextField from '@mui/material/TextField';
import Button from '@mui/material/Button';
import { GET_ALL_USERS, GET_USER_PROFILE, UPDATE_USER } from '../../gql/users';
import LoadingScreen from '../../components/loadingScreen';
import Header from '../../components/header';
import useSnackbar from '../../hooks/useSnackbar';
import { profileSchema, updatePasswordSchema } from './form/schema';
import Avatar from '@mui/material/Avatar';
import FormControl from '@mui/material/FormControl';
import FormHelperText from '@mui/material/FormHelperText';
import InputLabel from '@mui/material/InputLabel';
import MenuItem from '@mui/material/MenuItem';
import Select from '@mui/material/Select';
import { GET_ALL_PARTNERS } from '../../gql/partners';
import { userRolesMap } from '../../utils/forms';
import KeyIcon from '@mui/icons-material/Key';
import DeleteIcon from '@mui/icons-material/Delete';
import Box from '@mui/system/Box';
import TableDescription from '../../components/tableDescription';
import InputMask from 'react-input-mask';

const Profile = () => {
  const [changePassword, setChangePassword] = useState<boolean>(false);
  const navigate = useNavigate();
  const snackbar = useSnackbar();
  const { data, loading, error } = useQuery(GET_USER_PROFILE);
  const [mutateUser] = useMutation(UPDATE_USER, {
    refetchQueries: [{ query: GET_ALL_USERS }, { query: GET_USER_PROFILE }],
  });

  const toggleChange = () => setChangePassword(!changePassword);

  const navigateBack = () => navigate('/dashboard/partners');

  const handleSubmit = (values: Record<any, any>) => {
    snackbar.setGlobalLoading(true);
    snackbar.sendAlert({ level: 'info', message: 'Salvando Dados...' });

    // remove password fields from values object
    const { password, confirmPassword: _, partner, ...others } = values;

    const input: Record<string, any> = changePassword
      ? { ...others, password }
      : { ...others, partnerId: values.partnerId || partner?.id };

    mutateUser({ variables: { input } })
      .then(() => {
        snackbar.sendAlert({ level: 'success', message: 'Dados salvos com sucesso ' });
        navigateBack();
      })
      .catch((er) => {
        snackbar.sendAlert({
          level: 'error',
          message: `Erro Salvando dados: ${JSON.stringify(er)}`,
        });
        console.error(er);
      })
      .finally(() => {
        snackbar.setGlobalLoading(false);
      });
  };

  const formik = useFormik({
    initialValues: data?.me
      ? {
          ...data.me,
          password: '',
          confirmPassword: '',
        }
      : {},
    validationSchema: changePassword ? updatePasswordSchema : profileSchema,
    onSubmit: handleSubmit,
    enableReinitialize: true,
  });
  const { data: partnerData } = useQuery(GET_ALL_PARTNERS);

  useEffect(() => {
    formik.validateForm();
  }, [changePassword]);

  if (error) console.error(error);

  return (
    <>
      {loading && <LoadingScreen />}
      <Header title="Profile" />
      <Paper sx={{ width: '100%', mb: 2, p: 2 }}>
        <form onSubmit={formik.handleSubmit}>
          <Grid container spacing={4}>
            <Grid xs={12} item mt={5} sx={{ mt: 2 }}>
              <Box display="flex" justifyContent="center" marginBottom={-6}>
                <Avatar
                  style={{
                    width: 100,
                    height: 100,
                    cursor: 'pointer',
                  }}
                >
                  {formik.values.firstName || formik.values.lastName
                    ? `${formik.values.firstName ? formik.values.firstName[0] : ''} ${
                        formik.values.lastName ? formik.values.lastName[0] : ''
                      }`
                    : ''}
                </Avatar>
              </Box>
            </Grid>
            <Grid xs={12} item />
            <Grid container item spacing={2} xs={12}>
              <Grid item xs={6}>
                <TextField
                  fullWidth
                  margin="normal"
                  id="firstName"
                  label="Nome"
                  name="firstName"
                  value={formik.values.firstName ?? ''}
                  onChange={formik.handleChange}
                  error={formik.touched.firstName && Boolean(formik.errors.firstName)}
                  helperText={formik.touched.firstName && formik.errors.firstName}
                  variant="standard"
                />
              </Grid>
              <Grid item xs={6}>
                <TextField
                  fullWidth
                  margin="normal"
                  id="lastName"
                  label="Sobrenome"
                  name="lastName"
                  value={formik.values.lastName ?? ''}
                  onChange={formik.handleChange}
                  error={formik.touched.lastName && Boolean(formik.errors.lastName)}
                  helperText={formik.touched.lastName && formik.errors.lastName}
                  variant="standard"
                />
              </Grid>
              <Grid item xs={6} marginTop={4}>
                <FormControl fullWidth variant="standard">
                  {/* @ts-ignore */}
                  <InputMask
                    mask="999.999.999.99"
                    value={formik.values.cpf ?? ''}
                    disabled={false}
                    onChange={({ target }) => {
                      formik.setFieldValue(
                        'cpf',
                        String(target.value.replace(/[^\d]/g, ''))
                      );
                    }}
                  >
                    <TextField
                      fullWidth
                      autoFocus
                      placeholder="00.000.000/0000-00"
                      error={Boolean(formik.errors.cpf)}
                      name="cpf"
                      id="cpf-mask"
                      variant="standard"
                      label="cpf"
                      helperText={formik.errors.cpf}
                    />
                  </InputMask>
                </FormControl>
              </Grid>
              <Grid item xs={6}>
                <TextField
                  margin="normal"
                  fullWidth
                  id="email"
                  label="Email"
                  name="email"
                  value={formik.values.email ?? ''}
                  onChange={formik.handleChange}
                  error={formik.touched.email && Boolean(formik.errors.email)}
                  helperText={formik.touched.email && formik.errors.email}
                  variant="standard"
                />
              </Grid>
              <Grid item xs={6}>
                <FormControl fullWidth>
                  <InputLabel id="partnerId" sx={{ marginLeft: -1.8 }}>
                    Parceiro
                  </InputLabel>
                  <Select
                    required
                    variant="standard"
                    labelId="partnerId"
                    id="partnerId"
                    value={formik.values.partnerId ?? ''}
                    label="partnerId"
                    name="partnerId"
                    onChange={formik.handleChange}
                    error={formik.touched.partnerId && Boolean(formik.errors.partnerId)}
                  >
                    {partnerData?.getAllPartners?.map((item: Record<any, any>) => (
                      <MenuItem value={item.id} key={item.id}>
                        {item.tradingName}
                      </MenuItem>
                    ))}
                  </Select>
                  <FormHelperText>{formik.errors.partnerId}</FormHelperText>
                </FormControl>
              </Grid>
              <Grid item xs={6}>
                <FormControl fullWidth>
                  <InputLabel id="Grupo">Grupo</InputLabel>
                  <Select
                    required
                    labelId="role"
                    id="role"
                    value={formik.values.role ?? 0}
                    label="Role"
                    name="role"
                    variant="standard"
                    onChange={formik.handleChange}
                    error={formik.touched.role && Boolean(formik.errors.role)}
                  >
                    {userRolesMap.map((item, index) => (
                      <MenuItem key={item} value={index}>
                        {item}
                      </MenuItem>
                    ))}
                  </Select>
                </FormControl>
              </Grid>
            </Grid>
            <Grid
              container
              item
              spacing={2}
              xs={12}
              display="flex"
              marginBottom={5}
              align-items=" center"
              justifyContent="space-between"
            >
              <Grid item xs={6} justifyContent="flex-start">
                <Button variant="outlined" size="small" onClick={toggleChange}>
                  <KeyIcon fontSize="small" sx={{ rotate: '270deg', marginRight: 1 }} />{' '}
                  Alterar senha
                </Button>
              </Grid>
              <Grid justifyContent="flex-end">
                <Button variant="outlined" color="error" size="small" type="submit">
                  <DeleteIcon fontSize="small" /> Excluir Usuário
                </Button>
              </Grid>
              {changePassword && (
                <>
                  <Grid item xs={12}>
                    <TextField
                      margin="normal"
                      fullWidth
                      type="password"
                      id="password"
                      label="Senha"
                      name="password"
                      value={formik.values.password ?? ''}
                      onChange={formik.handleChange}
                      error={formik.touched.password && Boolean(formik.errors.password)}
                      helperText={formik.touched.password && formik.errors.password}
                      variant="standard"
                    />
                  </Grid>
                  <Grid item xs={12}>
                    <TextField
                      margin="normal"
                      fullWidth
                      type="password"
                      id="confirmPassword"
                      label="Confirmar senha"
                      name="confirmPassword"
                      value={formik.values.confirmPassword ?? ''}
                      onChange={formik.handleChange}
                      error={
                        formik.touched.confirmPassword &&
                        Boolean(formik.errors.confirmPassword)
                      }
                      helperText={
                        formik.touched.confirmPassword && formik.errors.confirmPassword
                      }
                      variant="standard"
                    />
                  </Grid>
                </>
              )}
            </Grid>
            <Grid item container spacing={2} xs={12} justifyContent="flex-end">
              <Grid item>
                <Button size="large" onClick={navigateBack}>
                  Cancelar
                </Button>
              </Grid>
              <Grid item>
                <Button variant="contained" size="large" type="submit">
                  Salvar
                </Button>
              </Grid>
            </Grid>
          </Grid>
        </form>
      </Paper>
      <Grid container item>
        <TableDescription />
      </Grid>
    </>
  );
};

export default Profile;
