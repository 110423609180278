import styled from 'styled-components';
import Box from '@mui/material/Box';
import Container from '@mui/material/Container';
import Paper from '@mui/material/Paper';

export const LoginContainer = styled(Container)`
  display: grid;
  grid-template-columns: 1fr 1fr;
  padding: 0;
  background-color: #fefffe;
  background-size: cover;
  background-repeat: no-repeat;
  height: 100vh;
  max-width: none;
`;

export const LoginBox = styled(Box)`
  display: flex;
  flex-direction: column;
  align-items: center;
`;

export const LoginPaper = styled(Paper)`
  margin-top: 6em;
  padding: 2em 3em;
  display: flex;
  align-items: center;
  height: 100%;
  max-width: 550px;
`;

export const Input = styled.input`
  width: 100%;
  padding: 10px;
  border: 1px solid #f0f0f0;
  border-radius: 4px;
  outline: none;
  transition: border-color 0.3s;
  font-size: 16px;
  margin-bottom: 25px
`;

export const Label = styled.label`
  display: block;
  font-weight: bold;
  color: '#000';
  margin-bottom: 8px;
`;
