import React, { useEffect, useState } from 'react';
import Toolbar from '@mui/material/Toolbar';
import Badge from '@mui/material/Badge';
import IconButton from '@mui/material/IconButton';
import Box from '@mui/material/Box';
import { useLocation, useNavigate } from 'react-router-dom';
import AppBar from '@mui/material/AppBar';
import Menu from '@mui/material/Menu';
import Avatar from '@mui/material/Avatar';
import MenuItem from '@mui/material/MenuItem';
import Typography from '@mui/material/Typography';
import Tabs from '@mui/material/Tabs';
import { useApolloClient, useQuery } from '@apollo/client';
import useAuthToken from '../../hooks/useAuthToken';
import { GET_USER_PROFILE } from '../../gql/users';
import tundraLogo from '../../assets/images/tundra.svg';
import Handshake from '@mui/icons-material/Handshake';
import PeopleAlt from '@mui/icons-material/PeopleAlt';
import PointOfSale from '@mui/icons-material/PointOfSale';
import Person from '@mui/icons-material/Person';
import HomeIcon from '@mui/icons-material/Home';
import HeaderMenuItem from './components/headerMenuItem';
import { MenuItemI } from '../../types/menu';
import AlertsMenu from '../alertsMenu';
import Popover from '@mui/material/Popover';
import Grid from '@mui/material/Grid';
import usePermissions from '../../hooks/usePermissions';
import engine from '../../assets/images/engine.svg';
import email from '../../assets/images/email.svg';
import sino from '../../assets/images/sino.svg';

const ActivityMenu: React.FC = () => {
  const { isSalesAdmin, isSalesUser } = usePermissions();

  const menus: MenuItemI[] = [
    {
      name: 'Inicio',
      icon: <HomeIcon fontSize="large" color="primary" />,
      location: '/dashboard',
      menus: [],
    },
    {
      name: 'Administração',
      icon: <PeopleAlt fontSize="large" />,
      menus: [
        {
          name: 'Cadastros',
          icon: <Person fontSize="medium" color="primary" />,
          location: 'users',
          entity: 'User',
        },
      ],
    },
    {
      name: 'Negocios',
      icon: <Handshake fontSize="large" />,
      menus: [
        {
          name: 'Financeiro',
          icon: <PointOfSale fontSize="medium" color="primary" />,
          location: 'invoices',
          entity: 'Invoice',
        },
      ],
    },
  ];

  const { data } = useQuery(GET_USER_PROFILE);
  const navigate = useNavigate();
  const location = useLocation();
  const [activeSection, setActiveSection] = useState(0);

  const routeSections = [
    {
      routes: [
        '/dashboard/users',
        '/dashboard/groups',
        '/dashboard/partners',
        '/dashboard/calendar',
        '/dashboard/dashboards',
      ],
      condition: isSalesAdmin || isSalesUser,
      section: 1,
    },
    {
      routes: [
        '/dashboard/invoices',
        '/dashboard/contracts',
        '/dashboard/products',
        '/dashboard/taxes',
        '/dashboard/wallets',
        '/dashboard/alerts',
      ],
      condition: isSalesAdmin,
      section: 2,
    },
  ];

  useEffect(() => {
    const getActiveSectionFromRoute = () => {
      if (!location || !location.pathname) return;

      const matchedSection = routeSections.find(
        ({ routes, condition }) =>
          condition && routes.some((route) => location.pathname.startsWith(route))
      );

      setActiveSection(matchedSection ? matchedSection.section : 0);
    };

    getActiveSectionFromRoute();
  }, [location.pathname]);

  const handleTabChange = (event: any, newValue: any) => {
    setActiveSection(newValue);
  };

  const [, , removeAuthToken] = useAuthToken();
  const apolloClient = useApolloClient();
  const [anchorElUser, setAnchorElUser] = React.useState<null | HTMLElement>(null);
  const [anchorEl, setAnchorEl] = React.useState<HTMLButtonElement | null>(null);

  const handleClick = (event: React.MouseEvent<HTMLButtonElement>) => {
    setAnchorEl(event.currentTarget);
  };

  const handleClose = () => {
    setAnchorEl(null);
  };

  const open = Boolean(anchorEl);
  const id = open ? 'alerts-popover' : undefined;

  const handleOpenUserMenu = (event: React.MouseEvent<HTMLElement>) => {
    setAnchorElUser(event.currentTarget);
  };

  const handleHome = () => navigate('/dashboard');

  const handleCloseUserMenu = () => setAnchorElUser(null);

  const handleProfile = () => {
    navigate('/dashboard/profile');
    handleCloseUserMenu();
  };

  const handleLogout = () => {
    removeAuthToken();
    apolloClient.clearStore();
    navigate('/login');
  };

  return (
    <AppBar
      color="transparent"
      position="fixed"
      sx={{ height: '80px', backgroundColor: '#fff', paddingRight: '30px' }}
    >
      <Toolbar variant="dense" sx={{ justifyContent: 'space-between', height: '80px' }}>
        <Box sx={{ cursor: 'pointer' }} onClick={handleHome}>
          <img
            src={tundraLogo}
            alt=""
            style={{
              maxHeight: '32px',
              width: '150px',
              cursor: 'pointer',
              marginLeft: '40px',
            }}
          />
        </Box>
        <Box>
          <Tabs
            value={activeSection}
            sx={{ paddingTop: '7px' }}
            onChange={handleTabChange}
          >
            {menus.map((menu) => (
              <HeaderMenuItem item={menu} key={menu.name} />
            ))}
          </Tabs>
        </Box>
        <Box sx={{ display: 'flex' }}>
          <IconButton color="inherit" style={{ width: 45, height: 45 }} sx={{ mt: 1 }}>
            <Badge badgeContent={0} color="error">
              <img src={engine} alt="settings" />
            </Badge>
          </IconButton>
          <IconButton color="inherit" style={{ width: 45, height: 45 }} sx={{ mt: 1 }}>
            <Badge badgeContent={0} color="error">
              <img src={email} alt="email" />
            </Badge>
          </IconButton>
          <IconButton
            color="inherit"
            style={{ width: 45, height: 45 }}
            sx={{ mt: 1 }}
            onClick={handleClick}
          >
            <Badge badgeContent={0} color="error">
              <img src={sino} alt="notifications" />
            </Badge>
          </IconButton>
          <Popover
            id={id}
            open={open}
            anchorEl={anchorEl}
            onClose={handleClose}
            anchorOrigin={{ vertical: 'bottom', horizontal: 'left' }}
          >
            <Grid sx={{ width: '450px', height: '395px' }}>
              <AlertsMenu />
            </Grid>
          </Popover>

          <Box sx={{ flexGrow: 0 }}>
            <IconButton
              size="large"
              edge="end"
              color="inherit"
              onClick={handleOpenUserMenu}
            >
              <Avatar
                sx={{
                  background: '#95FFB3',
                  color: '#000',
                  border: '1px solid black',
                }}
              >
                {data?.me?.firstName?.[0]}
              </Avatar>
            </IconButton>
            <Menu
              sx={{ mt: '25px' }}
              id="menu-appbar"
              anchorEl={anchorElUser}
              anchorOrigin={{ vertical: 'top', horizontal: 'right' }}
              keepMounted
              transformOrigin={{ vertical: 'top', horizontal: 'right' }}
              open={Boolean(anchorElUser)}
              onClose={handleCloseUserMenu}
            >
              <MenuItem onClick={handleProfile}>
                <Typography textAlign="center">Profile</Typography>
              </MenuItem>
              <MenuItem onClick={handleLogout}>
                <Typography textAlign="center">Logout</Typography>
              </MenuItem>
            </Menu>
          </Box>
        </Box>
      </Toolbar>
    </AppBar>
  );
};

export default ActivityMenu;
