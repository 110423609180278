import React from 'react';
import Button from '@mui/material/Button';
import Grid from '@mui/material/Grid';
import Box from '@mui/material/Box';
import InputAdornment from '@mui/material/InputAdornment';
import TextField from '@mui/material/TextField';
import Typography from '@mui/material/Typography';
import SearchIcon from '@mui/icons-material/Search';
import Container from '@mui/material/Container';
import { useQuery } from '@apollo/client';
import { GET_USER_PROFILE } from '../../gql/users';
import { GridContainerCenter } from './style';
import CardsAmount from '../../components/cardsAmount';
import FolderCopyIcon from '@mui/icons-material/FolderCopy';
import GenericTableClient from '../../components/genericTableClient';

const Home = () => {
  const { data } = useQuery(GET_USER_PROFILE);

  return (
    <Container maxWidth="xl">
      <Grid container flexDirection="column-reverse">
        <GridContainerCenter item container xs={12} md={6}>
          <TextField
            sx={{ backgroundColor: 'rgba(255, 255, 255, 1)' }}
            style={{ border: 'none', borderRadius: '10%' }}
            fullWidth
            label="O que você procura"
            variant="outlined"
            InputProps={{
              endAdornment: (
                <InputAdornment position="end">
                  <Button>
                    <SearchIcon />
                  </Button>
                </InputAdornment>
              ),
            }}
          />
        </GridContainerCenter>
        <Grid container item sx={{ mt: 1, mb: 3 }}>
          <Typography variant="h5">
            Bem vindo ao portal de parceiros,{' '}
            <Typography component="span" variant="h5">
              {' '}
              {data?.me?.firstName}
            </Typography>
          </Typography>
        </Grid>
        <Box
          sx={{
            display: 'flex',
            justifyContent: 'space-between',
            flexWrap: 'wrap',
            '& > :not(style)': {
              width: 'calc(50% - 8px)',
              height: 400,
              marginBottom: 6,
            },
          }}
        />
        <Box
          sx={{
            display: 'flex',
            justifyContent: 'space-between',
            flexWrap: 'wrap',
            '& > :not(style)': {
              width: 'calc(100% - 0px)',
              height: 400,
              marginBottom: '16px',
            },
          }}
        />
      </Grid>
      <Grid container justifyContent="space-between" mt={10}>
        <CardsAmount icon={<FolderCopyIcon />} title="Novos contratos" value="R$ 2.000" />
        <CardsAmount icon={<FolderCopyIcon />} title="Novos contratos" value="R$ 2.000" />
        <CardsAmount icon={<FolderCopyIcon />} title="Novos contratos" value="R$ 2.000" />
      </Grid>
      <Box style={{ paddingTop: 16 }}>
        <GenericTableClient rows={[]} columns={[]} />
      </Box>
    </Container>
  );
};

export default Home;
