import React, { useState } from 'react';
import { DataGrid } from '@mui/x-data-grid/DataGrid';
import { GridColDef } from '@mui/x-data-grid/models/colDef';
import Box from '@mui/material/Box';
import IconButton from '@mui/material/IconButton';
import Paper from '@mui/material/Paper';
import TextField from '@mui/material/TextField';
import FilterAltIcon from '@mui/icons-material/FilterAlt';
import { defaultTheme, spacing } from '../../theme/themeCreator';

interface CustomTableProps {
  rows: { id: number; empresa: string; contratos: number }[];

  columns: { field: string; headerName: string; flex: number }[];
}
const GenericTableClient: React.FC<CustomTableProps> = () => {
  const [filterValue, setFilterValue] = useState('');

  const rows = [
    { id: 1, empresa: 'Empresa Lorem Ipsum', contratos: 2 },
    { id: 2, empresa: 'Empresa ABC', contratos: 3 },
    { id: 3, empresa: 'Empresa XYZ', contratos: 1 },
    { id: 4, empresa: 'Empresa Delta', contratos: 4 },
    { id: 5, empresa: 'Empresa Lorem Ipsum', contratos: 5 },
  ];

  const columns: GridColDef[] = [
    { field: 'empresa', headerName: 'Empresa', flex: 1 },
    {
      field: 'contratos',
      headerName: 'N. de Contratos',
      flex: 1,
      headerAlign: 'right',
      align: 'right',
    },
  ];

  const handleFilterValue = (event: React.ChangeEvent<HTMLInputElement>) => {
    setFilterValue(event.target.value);
  };

  return (
    <Paper
      sx={{
        display: 'flex',
        flexDirection: 'column',
        padding: 2,
        gap: spacing.xl,
      }}
    >
      <Box
        sx={{
          display: 'flex',
          alignItems: 'center',
          justifyContent: 'flex-end',
          gap: spacing.md,
        }}
      >
        <IconButton>
          <FilterAltIcon />
        </IconButton>
        <TextField
          placeholder="Filtrar..."
          variant="standard"
          size="small"
          value={filterValue}
          onChange={handleFilterValue}
        />
      </Box>

      <Box sx={{ height: 400, flex: 1 }}>
        <DataGrid
          rows={rows}
          columns={columns}
          disableRowSelectionOnClick
          filterModel={{
            items: [
              {
                field: 'empresa',
                operator: 'contains',
                value: filterValue,
              },
            ],
          }}
          sx={{
            border: 'none',
            '& .MuiDataGrid-row:hover': {
              backgroundColor: `${defaultTheme.secondaryColor}`,
            },
            '& .MuiDataGrid-cell:focus': {
              outline: 'none',
            },
            '& .MuiDataGrid-row.Mui-selected:focus-within': {
              outline: 'none',
            },
            '& .MuiDataGrid-columnHeader:focus': {
              outline: 'none',
            },
          }}
        />
      </Box>
    </Paper>
  );
};

export default GenericTableClient;
