import React from 'react';
import Dialog from '@mui/material/Dialog';
import DialogTitle from '@mui/material/DialogTitle';
import DialogContent from '@mui/material/DialogContent';
import DialogContentText from '@mui/material/DialogContentText';
import DialogActions from '@mui/material/DialogActions';
import Button from '@mui/material/Button';

interface CustomDialogProps {
  title: string;
  content: string;
  open: boolean;
  onClose: () => void;
  actions?: {
    onClick: () => void;
    title: string;
  }[];
}

const CustomDialog: React.FC<CustomDialogProps> = ({
  open,
  onClose,
  title,
  content,
  actions,
}) => (
  <Dialog open={open} onClose={onClose}>
    <DialogTitle id="alert-dialog-title">{title}</DialogTitle>
    <DialogContent>
      <DialogContentText id="alert-dialog-description">{content}</DialogContentText>
    </DialogContent>
    <DialogActions>
      {actions?.map((action, index) => (
        <Button key={`${action?.title}-action-${index}`} onClick={action.onClick}>
          {action.title}
        </Button>
      ))}
    </DialogActions>
  </Dialog>
);

export default CustomDialog;
