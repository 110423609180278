import React from 'react';
import { CardContainer, IconWrapper, Title, Value, ViewMore } from './style';
import Box from '@mui/material/Box';

interface CardProps {
  icon: React.ReactNode;
  title: string;
  value: string;
}

const CardsAmount: React.FC<CardProps> = ({ icon, title, value }) => (
  <CardContainer>
    <Box display="flex" alignItems="center" gap={3}>
      <IconWrapper>{icon}</IconWrapper>
      <Title>{title}</Title>
    </Box>
    <Value>{value}</Value>
    <Box display="flex" justifyContent="flex-end" width="100%">
      <ViewMore href="#">Ver mais</ViewMore>
    </Box>
  </CardContainer>
);

export default CardsAmount;
