import React, { useEffect } from 'react';
import { useMutation } from '@apollo/client';
import Grid from '@mui/material/Grid';
import Button from '@mui/material/Button';
import Box from '@mui/material/Box';
import Link from '@mui/material/Link';
import Alert from '@mui/material/Alert';
import Typography from '@mui/material/Typography';
import LoadingScreen from '../../../components/loadingScreen';
import tundraLogo from '../../../assets/images/tundra.svg';
import useAuthToken from '../../../hooks/useAuthToken';
import { LOGIN } from '../../../gql/login';
import { Input, Label } from '../styles';
import { spacing } from '../../../theme/themeCreator';

interface LoginFormProps {
  toggleReset: () => void;
  
}

const LoginForm: React.FC<LoginFormProps> = ({ toggleReset }) => {
  const [, setAuthToken] = useAuthToken();
  const [login, { data, loading, error }] = useMutation(LOGIN);

  useEffect(() => {
    if (data?.login) {
      const { access_token: token } = data.login;
      setAuthToken(token);
    }
    if (error) {
      console.error(error);
    }
  }, [data, error, setAuthToken]);

  const handleSubmit = (event: React.FormEvent<HTMLFormElement>) => {
    event.preventDefault();
    const formData = new FormData(event.currentTarget);
    const userCredentials = {
      email: formData.get('email'),
      password: formData.get('password'),
    };

    login({ variables: { userCredentials } });
  };

  const handleFocus = (e: React.FocusEvent<HTMLInputElement>) => {
    e.target.style.borderColor = 'limegreen';
  };

  const handleBlur = (e: React.FocusEvent<HTMLInputElement>) => {
    e.target.style.borderColor = '#f0f0f0';
  };

  return (
    <Grid container alignItems="center" justifyContent="center" spacing={2}>
      {loading && <LoadingScreen />}

      <Grid item>
        <img src={tundraLogo} alt="Tundra Logo" />
      </Grid>

      <Grid item xs={12}>
        <Box component="form" onSubmit={handleSubmit} noValidate sx={{ mt: 1 }}>
          <Label htmlFor="email">E-mail</Label>
          <Input
            type="email"
            id="email"
            name="email"
            placeholder="Seu e-mail"
            onFocus={handleFocus}
            onBlur={handleBlur}
          />

          <Label htmlFor="password">Senha</Label>
          <Input
            type="password"
            id="password"
            name="password"
            placeholder="Sua senha"
            onFocus={handleFocus}
            onBlur={handleBlur}
          />

          <Grid container justifyContent="space-between" alignItems="center">
            <Box sx={{ display: 'flex', alignItems: 'center', gap: spacing.md }}>
              <input type="checkbox" id="rememberMe" />
              <Typography>Manter-me conectado</Typography>
            </Box>

            <Link onClick={toggleReset} component="button" variant="body2" color="#000">
              Esqueci a senha
            </Link>
          </Grid>

          <Grid container justifyContent="flex-end">
            <Button
              type="submit"
              variant="contained"
              size="large"
              sx={{
                mt: 2,
                mb: 2,
                width: '30%',
                color: 'black',
                '&:hover': { backgroundColor: '#01AC01' },
              }}
            >
              Entrar
            </Button>
          </Grid>

          {error?.graphQLErrors?.map(({ message }, index) => (
            <Alert key={index} severity="error" sx={{ mt: 2 }}>
              {message}
            </Alert>
          ))}
        </Box>
      </Grid>
    </Grid>
  );
};

export default LoginForm;
