import styled from 'styled-components';
import { fontSizes } from '../../theme/themeCreator';

export const CardContainer = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: flex-start;
  width: 31%;
  height: 224px;
  padding: 20px;
  background-color: #fff;
  border-radius: 8px;
  box-shadow: 0 4px 6px rgba(0, 0, 0, 0.1);
  border-top: 5px solid #00c853;
`;

export const IconWrapper = styled.div`
  color: #666;
  padding-top: 5px;
`;

export const Title = styled.h3`
  font-size: ${fontSizes.md};
  font-weight: 500;
  color: #333;
  font-weight: bold;
  margin: 0;
`;

export const Value = styled.span`
  font-size: ${fontSizes.xxl};
  font-weight: bold;
  color: #005a2f;
`;

export const ViewMore = styled.a`
  margin-top: 12px;
  font-size: ${fontSizes.sm};
  color: rgb(0, 0, 0);
  text-decoration: underline;
  font-weight: bold;
  cursor: pointer;

  &:hover {
    text-decoration: underline;
  }
`;
