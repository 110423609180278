import React from 'react';
import Paper from '@mui/material/Paper';
import { useMutation, useQuery } from '@apollo/client';
import GenericTable from '../../components/genericTable';
import { useNavigate } from 'react-router-dom';
import { DELETE_WALLET, GET_ALL_WALLETS } from '../../gql/wallets';
import Typography from '@mui/material/Typography';
import usePermissions from '../../hooks/usePermissions';

const columnsOptions = {
  id: {
    hide: true,
  },
  partnerId: {
    headerName: 'ID do parceiro',
    hide: true,
  },
  tenantId: {
    hide: true,
  },
  partner: {
    headerName: 'Parceiro',
    valueGetter: ({ value }: Record<any, any>) => value.tradingName,
  },
  data: {
    headerName: 'Dados',
    renderCell: ({ value }: Record<any, any>) =>
      value.map((resource: Record<any, any>) => (
        <div key={resource.value} style={{ marginRight: '1em' }}>
          <Typography>
            {resource.key} {resource.namespace}
          </Typography>
          <Typography color="textSecondary">
            {resource.description ?? resource.value}
          </Typography>
        </div>
      )),
  },
};

const WalletsRoute = () => {
  const navigate = useNavigate();
  const { isAdmin } = usePermissions();
  const { data, loading } = useQuery(GET_ALL_WALLETS);
  const [deleteWallet] = useMutation(DELETE_WALLET, {
    refetchQueries: [{ query: GET_ALL_WALLETS }],
  });

  const handleEditWallet = ({ id = '' }: { id?: string }) =>
    navigate(`/dashboard/wallets/${id}`);
  const handleDeleteWallet = ({ id = '' }) => deleteWallet({ variables: { id } });

  return (
    <Paper sx={{ width: '100%', mb: 2 }}>
      <GenericTable
        data={data?.getAllWallets}
        loading={loading}
        columnOptions={columnsOptions}
        onEdit={handleEditWallet}
        onDelete={handleDeleteWallet}
        hideDeleteButton={!isAdmin}
      />
    </Paper>
  );
};

export default WalletsRoute;
