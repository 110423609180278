import React, { useEffect } from 'react';
import Paper from '@mui/material/Paper';
import { useMutation } from '@apollo/client';
import { useNavigate, useParams } from 'react-router-dom';
import Header from '../../../components/header';
import TextField from '@mui/material/TextField';
import Typography from '@mui/material/Typography';
import { useFormik } from 'formik';
import postSchema from './schema';
import Grid from '@mui/material/Grid';
import { TextareaAutosize } from '@mui/material';
import {
  GET_ALL_POSTS,
  // GET_POST_BY_ID,
  UPDATE_POST,
  CREATE_POST,
} from '../../../gql/posts';
import Button from '@mui/material/Button';
import useSnackbar from '../../../hooks/useSnackbar';
import { getTenantId } from '../../../services/tenancy';
import Autocomplete from '@mui/material/Autocomplete';

const PostForm = () => {
  const { id } = useParams();
  const [imgSrc, setImgSrc] = React.useState<string>('');
  const handleFileUpload = (event: any) => {
    const [file] = event.target.files;
    const url = URL.createObjectURL(file);
    setImgSrc(url);
  };
  const isCreate = id === 'create';
  const snackbar = useSnackbar();
  const navigate = useNavigate();
  const [mutatePost] = useMutation(isCreate ? CREATE_POST : UPDATE_POST, {
    refetchQueries: [{ query: GET_ALL_POSTS }],
  });
  // const [getCurrentPost, { loading, data, error }] = useLazyQuery(GET_POST_BY_ID, { fetchPolicy: 'no-cache' });

  const navigateBack = () => navigate('/dashboard/posts');

  const handleSubmit = (values: Record<any, any>) => {
    snackbar.setGlobalLoading(true);
    snackbar.sendAlert({ level: 'info', message: 'Salvando Dados...' });

    const input: Record<string, any> = { tenantId: getTenantId() };
    Object.keys(postSchema.fields).map((key) => {
      if (Object.hasOwn(values, key)) input[key] = values[key];
      return null;
    });
    mutatePost({ variables: { input } })
      .then(() =>
        snackbar.sendAlert({ level: 'success', message: 'Dados salvos com sucesso ' })
      )
      .catch((er) => {
        snackbar.sendAlert({ level: 'error', message: 'Erro Salvando dados' });
        console.error(er);
      })
      .finally(() => {
        snackbar.setGlobalLoading(false);
        navigateBack();
      });
  };

  const formik = useFormik({
    // initialValues: data?.getPostById ? {
    //   ...data.getPostById,
    //   postId: data.getPostById?.post?.id
    // } : {},
    initialValues: {} as Record<any, any>,
    validationSchema: postSchema,
    onSubmit: handleSubmit,
    enableReinitialize: true,
  });

  useEffect(() => {
    // if (!isCreate) getCurrentPost({ variables: { id } });
  }, [isCreate]);

  // if (error) console.error(error);
  console.log(formik.errors);
  return (
    <>
      {/* {(loading) && <LoadingScreen />} */}
      <Header title={`${isCreate ? 'Criar' : 'Editar'} Post`} />
      <Paper sx={{ width: '100%', mb: 2, p: 2 }}>
        <form onSubmit={formik.handleSubmit}>
          <Grid container spacing={4}>
            <Grid container item spacing={2}>
              <Grid item xs={12}>
                <TextField
                  fullWidth
                  autoFocus
                  variant="standard"
                  name="title"
                  label="Titulo"
                  value={formik.values.title ?? ''}
                  onChange={formik.handleChange}
                  error={Boolean(formik.errors.title)}
                  helperText={formik.errors.title}
                />
              </Grid>
              <Grid item xs={6}>
                <TextField
                  fullWidth
                  variant="standard"
                  label="Cover"
                  type="file"
                  onChange={handleFileUpload}
                />
              </Grid>
              {imgSrc && (
                <Grid item xs={12}>
                  <Typography>Imagem de Post</Typography>
                  <img
                    src={imgSrc}
                    loading="lazy"
                    style={{ maxHeight: 240 }}
                    alt="Cover"
                  />
                </Grid>
              )}
            </Grid>

            <Grid container item spacing={2}>
              <Grid item xs={12}>
                <Typography>Conteudo</Typography>
                <TextareaAutosize
                  minRows={10}
                  name="content"
                  maxRows={12}
                  style={{ width: '100%', maxWidth: '100%' }}
                  value={formik.values.content ?? ''}
                  onChange={formik.handleChange}
                />
              </Grid>
              <Grid item xs={8}>
                <Autocomplete
                  fullWidth
                  multiple
                  value={formik.values.tags ?? []}
                  onChange={formik.handleChange}
                  id="tags-autocomplete"
                  options={['test1', 'tes2']}
                  renderInput={(params) => (
                    <TextField name="tags" variant="standard" {...params} label="Tags" />
                  )}
                />
              </Grid>
              <Grid item xs={4}>
                <Autocomplete
                  fullWidth
                  value={formik.values.category ?? ''}
                  onChange={formik.handleChange}
                  id="category-autocomplete"
                  options={[]}
                  renderInput={(params) => (
                    <TextField
                      name="category"
                      variant="standard"
                      {...params}
                      label="Categoria"
                    />
                  )}
                />
              </Grid>
            </Grid>
            <Grid item container spacing={2} xs={12} justifyContent="flex-end">
              <Grid item>
                <Button size="large" onClick={navigateBack}>
                  Cancelar
                </Button>
              </Grid>
              <Grid item>
                <Button variant="contained" size="large" type="submit">
                  Salvar
                </Button>
              </Grid>
            </Grid>
          </Grid>
        </form>
      </Paper>
    </>
  );
};

export default PostForm;
