import styled from 'styled-components';
import Button from '@mui/material/Button';
import Grid from '@mui/material/Grid';
import { spacing } from '../../theme/themeCreator';

export const ButtonTopTable = styled(Button)`
  display: flex;
  align-items: center;
  gap: ${spacing.md};
  padding: 0 32px 9px 32px;
  &:hover {
    background-color: transparent;
  }
`;

export const GridFlex = styled(Grid)`
  display: flex;
  gap: ${spacing.sm};
`;
