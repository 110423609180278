import React, { useState } from 'react';
import { useMutation } from '@apollo/client';
import { useFormik } from 'formik';
import * as yup from 'yup';
import Button from '@mui/material/Button';
import TextField from '@mui/material/TextField';
import { requiredField } from '../../../utils/forms';
import { RESET_PASSWORD } from '../../../gql/users';
import Box from '@mui/material/Box';
import TundraLogo from '../../../assets/images/tundra.svg';
import arrowBack from '../assets/images/seta.svg';
import { spacing } from '../../../theme/themeCreator';

interface forgotPasswordProps {
  toggleReset: () => void;
}

const forgotPasswordSchema = yup.object({
  email: yup.string().email().required(requiredField('Email')),
});

const ForgotPasswordForm = ({ toggleReset }: forgotPasswordProps) => {
  const [showFeedback, setShowFeedback] = useState<boolean>(false);
  const [reset] = useMutation(RESET_PASSWORD);

  const handleSubmit = (values: Record<any, any>) => {
    const input: Record<any, any> = { ...values };

    reset({ variables: { input } })
      .then(() => setShowFeedback(!showFeedback))
      .catch((er) => console.error(er));
  };

  const formik = useFormik({
    initialValues: {
      email: '',
    },
    validationSchema: forgotPasswordSchema,
    onSubmit: handleSubmit,
  });

  return (
    <form onSubmit={formik.handleSubmit}>
      {showFeedback ? (
        <>
          <div>
            <p>
              Caso você tenha uma conta cadastrado conosco, você irá receber um email com
              instruções para resetar sua senha.
            </p>
          </div>
          <Button
            fullWidth
            variant="contained"
            size="large"
            onClick={toggleReset}
            sx={{ mt: 2, mb: 2 }}
          >
            Voltar para a tela de login
          </Button>
        </>
      ) : (
        <>
          <Box
            sx={{
              display: 'flex',
              justifyContent: 'center',
              alignItems: 'center',
              mb: 2,
            }}
          >
            <img src={TundraLogo} alt="tundra logo" />
          </Box>
          <TextField
            autoFocus
            margin="normal"
            fullWidth
            id="email"
            name="email"
            label="Email"
            value={formik.values.email ?? ''}
            onChange={formik.handleChange}
            error={formik.touched.email && Boolean(formik.errors.email)}
            helperText={formik.touched.email && formik.errors.email}
          />
          <Button
            fullWidth
            variant="contained"
            size="large"
            type="submit"
            sx={{ mt: 2, mb: 2 }}
          >
            Alterar senha
          </Button>
          <Button
            onClick={toggleReset}
            style={{
              display: 'flex',
              alignItems: 'center',
              gap: spacing.xl,
              color: '#000000a8',
              fontSize: '12px',
            }}
          >
            <img src={arrowBack} alt="" /> Voltar
          </Button>
        </>
      )}
    </form>
  );
};

export default ForgotPasswordForm;
