import React, { useEffect } from 'react';
import { useQuery, useMutation } from '@apollo/client';
import { useFormik } from 'formik';
import { useNavigate, useSearchParams } from 'react-router-dom';
import Button from '@mui/material/Button';
import TextField from '@mui/material/TextField';
import passwordResetSchema from './form/schema';
import useAuthToken from '../../hooks/useAuthToken';
import useSnackbar from '../../hooks/useSnackbar';
import { GET_MY_ID, UPDATE_USER } from '../../gql/users';
import LoadingScreen from '../../components/loadingScreen';
import { LoginBox, LoginContainer, LoginPaper } from '../login/styles';

const PasswordReset = () => {
  const [searchParams] = useSearchParams();
  const [, setAuthToken] = useAuthToken();
  const { data, loading, error } = useQuery(GET_MY_ID);
  const [mutateUser] = useMutation(UPDATE_USER);
  const navigate = useNavigate();
  const snackbar = useSnackbar();

  useEffect(() => {
    setAuthToken(searchParams.get('token'));
  }, [searchParams]);

  const handleSubmit = (values: Record<any, any>) => {
    snackbar.setGlobalLoading(true);
    snackbar.sendAlert({ level: 'info', message: 'Salvando Dados...' });

    const { password } = values;
    const input = {
      ...data.me,
      password,
    };

    mutateUser({ variables: { input } })
      .then(() => {
        snackbar.sendAlert({ level: 'success', message: 'Dados salvos com sucesso ' });
        navigate('/dashboard');
      })
      .catch((er) => {
        snackbar.sendAlert({
          level: 'error',
          message: `Erro Salvando dados: ${JSON.stringify(er)}`,
        });
        console.error(er);
        navigate('/login');
      })
      .finally(() => {
        snackbar.setGlobalLoading(false);
      });
  };

  const formik = useFormik({
    initialValues: {
      password: '',
      confirmPassword: '',
    },
    validationSchema: passwordResetSchema,
    onSubmit: handleSubmit,
  });

  if (error) console.error(error);
  return (
    <LoginContainer>
      {loading && <LoadingScreen />}
      <LoginBox>
        <LoginPaper elevation={12}>
          <form onSubmit={formik.handleSubmit}>
            <TextField
              margin="normal"
              fullWidth
              type="password"
              id="password"
              label="Senha"
              name="password"
              value={formik.values.password ?? ''}
              onChange={formik.handleChange}
              error={formik.touched.password && Boolean(formik.errors.password)}
              helperText={formik.touched.password && formik.errors.password}
              autoFocus
            />
            <TextField
              margin="normal"
              fullWidth
              type="password"
              id="confirmPassword"
              label="Confirmar senha"
              name="confirmPassword"
              value={formik.values.confirmPassword ?? ''}
              onChange={formik.handleChange}
              error={
                formik.touched.confirmPassword && Boolean(formik.errors.confirmPassword)
              }
              helperText={formik.touched.confirmPassword && formik.errors.confirmPassword}
            />
            <Button
              fullWidth
              variant="contained"
              size="large"
              type="submit"
              sx={{ mt: 2, mb: 2 }}
            >
              Alterar senha
            </Button>
          </form>
        </LoginPaper>
      </LoginBox>
    </LoginContainer>
  );
};

export default PasswordReset;
