import React, { useMemo } from 'react';
import Breadcrumbs from '@mui/material/Breadcrumbs';
import NavigateTextIcon from '@mui/icons-material/NavigateNext';
import Typography from '@mui/material/Typography';
import { useLocation } from 'react-router-dom';
import Link from '@mui/material/Link';
import Grid from '@mui/material/Grid';

interface HeaderProps {
  title: string;
}

const generateRoute = (crumbs: string[], index: number) => {
  if (index === 0) return `/${crumbs[index]}`;
  return `/${crumbs[index - 1]}/${crumbs[index]}`;
};
const translateRouteName = (routeName: string) => {
  switch (routeName) {
    case 'invoices':
      return 'Pedidos de Venda';
    case 'contracts':
      return 'Contratos';
    case 'partners':
      return 'Parceiros';
    case 'products':
      return 'Produtos';
    case 'wallets':
      return 'Conexões';
    case 'taxes':
      return 'Impostos';
    case 'groups':
      return 'grupos';
    case 'users':
      return 'Usuários';
    default:
      return 'Home';
  }
};

const Header = ({ title }: HeaderProps) => {
  const location = useLocation();
  const { pathname } = location;
  const breadcrumbs = useMemo(() => {
    const trail = pathname
      .split('/')
      .filter((v) => v)
      .slice(0, -1);
    const crumbs = trail.map((routeName, index) => (
      <Link
        sx={{ textTransform: 'capitalize' }}
        underline="hover"
        key={index + 1}
        color="inherit"
        href={generateRoute(trail, index)}
      >
        {translateRouteName(routeName)}
      </Link>
    ));
    return [
      ...crumbs,
      <Typography key="3" color="text.primary">
        {title}
      </Typography>,
    ];
  }, [pathname]);

  return (
    <Grid item>
      <Breadcrumbs
        sx={{ mb: 1, mt: 4 }}
        separator={<NavigateTextIcon fontSize="small" />}
        aria-label="breadcrumb"
      >
        {breadcrumbs}
      </Breadcrumbs>
      <Typography variant="h2" sx={{ mb: 3, fontWeight: '400' }}>
        {title}
      </Typography>
    </Grid>
  );
};

export default Header;
