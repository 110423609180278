import React from 'react';
import Paper from '@mui/material/Paper';
import { useMutation, useQuery } from '@apollo/client';
import GenericTable from '../../components/genericTable';
import { useNavigate } from 'react-router-dom';
import { GET_PRODUCTS, REMOVE_PRODUCTS } from '../../gql/products';
import Typography from '@mui/material/Typography';
import usePermissions from '../../hooks/usePermissions';

const columnsOptions = {
  id: {
    hide: true,
  },
  name: {
    headerName: 'Nome',
  },
  manufacturer: {
    headerName: 'Fabricante',
    valueGetter: ({ value }: Record<any, any>) => value.name,
  },
  resources: {
    hide: true,
  },
  tiers: {
    headerName: 'Tiers',
    hide: true,
    renderCell: ({ value }: Record<any, any>) =>
      value
        .slice()
        .sort((a: any, b: any) => a.tier - b.tier)
        .map((resource: Record<any, any>) => (
          <div key={resource.id} style={{ margin: '0.5em' }}>
            <Typography>
              Tier
              {resource.tier}
            </Typography>
            <Typography color="textSecondary">MMC: ${resource.mmc}</Typography>
          </div>
        )),
  },
};

const ProductsRoute = () => {
  const navigate = useNavigate();
  const { data, loading } = useQuery(GET_PRODUCTS);
  const [deleteProduct] = useMutation(REMOVE_PRODUCTS, {
    refetchQueries: [{ query: GET_PRODUCTS }],
  });

  const handleEditProduct = ({ id = '' }: { id?: string }) =>
    navigate(`/dashboard/products/${id}`);
  const handleDeleteProduct = ({ id = '' }) => deleteProduct({ variables: { id } });

  const { isAdmin } = usePermissions();

  return (
    <Paper sx={{ width: '100%', mb: 2 }}>
      <GenericTable
        data={data?.getAllProducts}
        loading={loading}
        columnOptions={columnsOptions}
        onEdit={handleEditProduct}
        onDelete={handleDeleteProduct}
        hideDeleteButton={!isAdmin}
      />
    </Paper>
  );
};

export default ProductsRoute;
