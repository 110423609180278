import styled from 'styled-components';
import Button from '@mui/material/Button';
import { spacing } from '../../theme/themeCreator';

const ButtonActionHeader = styled(Button)`
  background-color: ${({ theme }) => theme.palette.primary.main};
  color: #000;
  height: 48px;
  border-radius: 5px;
  display: flex;
  align-items: center;
  gap: ${spacing.sm};
  padding: ${spacing.sm} ${spacing.xl};
`;

export default ButtonActionHeader;
